import axiosInstance from '../../helper/axiosInstance'

import { LOGISTICS_ENDPOINT, REQUEST_ENDPOINT } from '../../constants/endpoints'



export const GetAllRequest = async () => {
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+REQUEST_ENDPOINT);
    return response;
};
