import React, { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addDays, isAfter, isBefore, format } from 'date-fns';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { ORDER_ENDPOINT, STORE_ENDPOINT } from '../constants/endpoints';
import { COMPANY_API } from '../models/company';
import { Loading } from './loading';
import CustomModal from './customModal';
import SelectorPrice from './selectorPrice';
import { GetAllItems } from '../redux/services/orderService';
import { ORDER_API } from '../models/order';
import { AutocompleteInput } from './autoCompleteInput';
import { areas } from '../constants/cites'
import { toast } from 'react-toastify';
import { TextArea } from './textArea';
import * as Yup from 'yup';
import { useFormik } from 'formik';

const validationSchema = Yup.object({
    customerName: Yup.string().required(words.customer_name_required),
    customerPhone: Yup.string().required(words.customer_mobile_number),
    deliveryPlace: Yup.string().required(words.delivery_place_required).oneOf(areas, 'העיר אינה תקינה'),
    deliveryStartTime: Yup.string().required(words.delivery_time_required),
    deliveryEndTime: Yup.string(),
    deliveryDate: Yup.date().required(words.delivery_date_required),
    deliveryPrice: Yup.number().nullable(),
    addressDetails: Yup.string().required(words.fields_required),
    deliveryHeight: Yup.string().required(words.delivery_height_required),
    done: Yup.boolean(),
    note: Yup.string(),
    vehicleObject: Yup.object().nullable(),
});

interface NewOrderFormProps {
    refresh: () => void;
    data: ORDER_API.Data[]
}

interface Item_list {
    id: string
    item: ORDER_API.item;
    quantity: number;
}
interface FormValues {
    customerName: string;
    customerPhone: string;
    deliveryPlace: string;
    deliveryStartTime: string;
    deliveryEndTime: string;
    deliveryDate: Date | null;
    deliveryPrice: string;
    addressDetails: string;
    deliveryHeight: string;
    done: boolean;
    note: string;
    vehicleObject: COMPANY_API.FullVehicle | null;
}

export const NewOrderForm: React.FC<NewOrderFormProps> = ({ refresh, data }) => {

    const [showVehicleList, setShowVehicleList] = useState(false);

    const [itemsError, setItemsError] = useState('');


    const [error, setError] = useState('');

    const [items, setItems] = useState<Item_list[]>([]);
    const [selectedItem, setSelectedItem] = useState<string | null>(null);
    const [quantity, setQuantity] = useState<string>('1.0');


    const { data: items_data, status: items_status, } = useQuery('items', GetAllItems);

    const createOrderMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.post(STORE_ENDPOINT + ORDER_ENDPOINT, data).then(() => {
                refresh();
                toast.success('הפעולה הושלמה בהצלחה');
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    const submit = (values:FormValues) => {
        if (items.length === 0) {
            setError(words.add_items)
            return;
        }
        setError('')

        const data = {
            customer_name: values.customerName,
            customer_phone: values.customerPhone,
            delivery_place: values.deliveryPlace,
            delivery_start_time: String(values.deliveryStartTime),
            delivery_end_time: values.deliveryEndTime ? String(values.deliveryEndTime) : String(values.deliveryStartTime),
            delivery_date: format(values.deliveryDate!, 'yyyy-MM-dd'),
            delivery_height: values.deliveryHeight,
            delivery_price: values.deliveryPrice,
            address_details: values.addressDetails,
            note: values.note,
            items: items,
            done: values.done,
            vehicle_id: values.vehicleObject ? values.vehicleObject.id : null,
        };

        createOrderMutation.mutate(data)
    }

    const formik = useFormik<FormValues>({
        initialValues: {
            customerName: '',
            customerPhone: '',
            deliveryPlace: '',
            deliveryStartTime: '',
            deliveryEndTime: '',
            deliveryDate: null,
            deliveryPrice: '0.0',
            addressDetails: '',
            deliveryHeight: '',
            done: false,
            note: '',
            vehicleObject: null,
        },
        
        validationSchema,
        validateOnChange:false,
        validateOnBlur: false,
        onSubmit: (values) => {
            submit(values)
        },  
    });
    
    if (items_status === 'loading') {
        return <Loading />
    }
    const handleChangeQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        const regex = /^[\d.\u0660-\u0669\u06F0-\u06F9\u0030-\u0039]*$/;

        if (!regex.test(inputValue)) {
            return;
        }

        setQuantity(inputValue);
    }

    const addItem = () => {
        const optionSelected = items_data.data.find((option: ORDER_API.item) => option.name === selectedItem);

        let q_value = Math.round(parseFloat(quantity) * 10) / 10;

        if (q_value < 0.1) {
            return
        }

        if (optionSelected) {
            const existingItem = items.find(item => item.item.name === optionSelected.name);

            if (existingItem) {
                setItems(prev => prev.map(item =>
                    item.id === existingItem.id
                        ? { ...item, quantity: item.quantity + q_value }
                        : item
                ));
            } else {
                setItems(prev => [...prev, {
                    id: Date().toString(),
                    item: optionSelected,
                    quantity: q_value
                }]);
            }

            setSelectedItem(null);
            setQuantity('');
        }
    };

    const deleteItem = (id: string) => {
        const updatedItems = items.filter(option => option.item.id !== id);
        setItems(updatedItems);
    };

    const handleShowPriceList = () => {
        setShowVehicleList(true);
    };
    const handleHidePriceList = () => {
        setShowVehicleList(false);
    };

    const handlePickVehivle = (vehicle: COMPANY_API.FullVehicle, price: any) => {
        formik.setFieldValue('vehicleObject', vehicle)
        formik.setFieldValue('deliveryPrice', price)
        setShowVehicleList(false);
    };

    const handleDateChange = (date: Date) => {
        const today = new Date();
        const sevenDaysAgo = addDays(today, -7);
        const thirtyDaysFromToday = addDays(today, 30);

        if (isBefore(date, sevenDaysAgo) || isAfter(date, thirtyDaysFromToday)) {
            formik.setFieldValue('deliveryDate', null)

        } else {
            formik.setFieldValue('deliveryDate', date)
        }
    };

    return (
        <>
            <Form onSubmit={formik.handleSubmit}>

                {error && <Alert variant="danger" className="mb-3">{error}</Alert>}

                <div className="row mb-3">
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={data.map(record => record.order.customer_name)}
                            showListOnClick={false}
                            matchedRequired={false}
                            label={words.customer_name}
                            name="customerName"
                            type="text"
                            value={formik.values.customerName}
                            externalOnBlur={formik.handleBlur}
                            onSuggestionSelect={(e) => formik.setFieldValue('customerName', e)}
                            error={formik.errors.customerName}
                        />
                    </div>
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={data.map(record => record.order.customer_phone)}
                            showListOnClick={false}
                            name='customerPhone'
                            matchedRequired={false}
                            label={words.customer_mobile_number}
                            type="text"
                            value={formik.values.customerPhone}
                            externalOnBlur={formik.handleBlur}
                            onSuggestionSelect={(e) => formik.setFieldValue('customerPhone', e)}
                            error={formik.errors.customerPhone}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <AutocompleteInput
                            type="text"
                            suggestions={areas}
                            label={words.delivery_place}
                            showListOnClick
                            matchedRequired
                            name='deliveryPlace'
                            value={formik.values.deliveryPlace}
                            externalOnBlur={formik.handleBlur}
                            onSuggestionSelect={(e) => formik.setFieldValue('deliveryPlace', e)}
                            error={formik.errors.deliveryPlace} />

                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.address}
                            type="text"
                            name='addressDetails'
                            value={formik.values.addressDetails}
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            error={formik.errors.addressDetails}
                        />
                    </div>
                </div>

                <div className='row mb-1'>
                    <div className="col-md-6">
                        <CustomInput
                            name='deliveryHeight'
                            label={words.delivery_height}
                            type="text"
                            onChange={formik.handleChange}
                            // onBlur={formik.handleBlur}
                            value={formik.values.deliveryHeight}
                            error={formik.errors.deliveryHeight}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 d-flex flex-column ">
                        <label htmlFor="deliveryDate" className="mb-1">
                            {words.delivery_date}
                        </label>
                        <DatePicker
                            id="deliveryDate"
                            name='deliveryDate'
                            selected={formik.values.deliveryDate}
                            onChange={handleDateChange}
                            onBlur={formik.handleBlur}
                            className={`form-control ${formik.errors.deliveryDate ? 'is-invalid' : ''}`}
                            dateFormat="yyyy-MM-dd"
                            autoComplete="off"
                        />
                        {formik.errors.deliveryDate && <div className="text-danger mt-1">{formik.errors.deliveryDate}</div>}
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeStart" className="mb-1">
                                {words.From_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeStart"
                                name='deliveryStartTime'
                                value={formik.values.deliveryStartTime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                            />
                            {formik.errors.deliveryStartTime && <div className="text-danger mt-1">{formik.errors.deliveryStartTime}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeEnd" className="mb-1">
                                {words.To_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeEnd"
                                name='deliveryEndTime'
                                value={formik.values.deliveryEndTime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <CustomInput
                            label={words.vehicle_number}
                            type="text"
                            value={formik.values.vehicleObject?.name ? formik.values.vehicleObject?.name : 'Not selected yet'}
                            disable={true}
                        />
                    </div>
                    <div className="col-md-3 align-items-center align-self-center mt-3">
                        <Button variant="warning" onClick={() => handleShowPriceList()}>
                            {words.selectVehicle}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.price}
                            type="text"
                            name='deliveryPrice'
                            value={formik.values.deliveryPrice}
                            disable={true}
                        />
                    </div>
                </div>

                <div className='row border m-2'>
                    <div className="p-4">
                        <ul className="list-group mb-3 items-list ">
                            {
                                items.length === 0 ?
                                    <Alert variant='warning'>{words.add_items}</Alert>
                                    :
                                    items.map(option => (
                                        <li key={option.id} className="list-group-item d-flex justify-content-between align-items-center">
                                            {option.item.name}  * {option.quantity}
                                            <button className="btn btn-danger btn-sm" onClick={() => deleteItem(option.item.id)}>Delete</button>
                                        </li>
                                    ))
                            }
                        </ul>

                        <div className="d-flex justify-content-center">
                            <Form.Group controlId="item" className="mx-2">
                                <Form.Label>{words.select_item}</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={selectedItem || ""}
                                    onChange={(e) => setSelectedItem(e.target.value)}
                                >
                                    <option value="" disabled>
                                        {words.select_item}
                                    </option>

                                    {items_data.data.map((option: ORDER_API.item, index: React.Key | null | undefined) => (
                                        <option key={index} value={option.name}>{option.name}</option>
                                    ))}
                                </Form.Control>
                            </Form.Group>

                            <CustomInput
                                label={words.quantity}
                                className="mx-2"
                                value={quantity}
                                onChange={(e) => handleChangeQuantity(e)}
                                placeholder="1.0"
                                disable={selectedItem ? false : true}
                            />

                            <div style={{ height: '40%' }} className='d-flex align-self-center mt-3 '>
                                <Button
                                    className="responsive-button mx-2"
                                    size='sm'
                                    onClick={addItem}
                                    disabled={selectedItem && quantity.length != 0 ? false : true}
                                    type='button'>
                                    {words.add_items}
                                </Button>
                            </div>

                        </div>
                        {itemsError && <Alert variant="danger" className="mb-3">{itemsError}</Alert>}
                    </div>
                </div>

                <div className='row mb-3'>
                    <TextArea
                        label={words.note}
                        name='note'
                        value={formik.values.note}
                        onChange={formik.handleChange} />
                </div>

                <div className="row my-2">
                    <div className="col-md-6">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={words.done}
                            name='done'
                            checked={formik.values.done}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button
                        variant="primary"
                        className="responsive-button"
                        size="lg"
                        type="submit"
                    >
                        {words.save_changes}
                    </Button>
                </div>
            </Form>


            <CustomModal show={showVehicleList} onHide={handleHidePriceList} size="xl" title={words.price_table_list}>
                <SelectorPrice pick={handlePickVehivle} />
            </CustomModal>
        </>
    );
};
