import * as Yup from 'yup';
import React, { useState } from 'react';
import { useFormik } from 'formik';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { useMutation, useQuery } from 'react-query';
import { GetAllSupplier } from '../redux/services/supplierService';
import { LOGISTICS_ENDPOINT, REQUEST_ENDPOINT } from '../constants/endpoints';
import apiInstance from '../helper/axiosInstance';
import { SUPPLIER_API } from '../models/supplier';
import { CustomInput } from './input';
import { AutocompleteInput } from './autoCompleteInput';

import { GetAllItems } from '../redux/services/orderService';
import { ORDER_API } from '../models/order';
import { Loading } from './loading';
import { toast } from 'react-toastify';
import { createWhatsAppMessage } from '../helper/whatsApp';
import { TextArea } from './textArea';
import { REQUEST_API } from '../models/request';
import { DateFormat, TimeFormat } from '../helper/time';

interface AddNewRequestFormProps {
    refresh: () => void;
    data: REQUEST_API.Data
    onHide: () => void
}

export const EditRequestForm: React.FC<AddNewRequestFormProps> = ({ refresh, data, onHide }) => {
    const [error, setError] = useState<string>('');


    const formik = useFormik({
        initialValues: {
            note: data.request.note,
            done: data.request.done
        },
        onSubmit: (values) => {
            handleSubmitAPI(values);
        },
    });


    const editRequestMutation = useMutation<void, unknown, any>(
        (form) => {
            return apiInstance.patch(`${LOGISTICS_ENDPOINT}${REQUEST_ENDPOINT}${data.request.id}/`, form).then(() => {
                onHide();
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    const handleSubmitAPI = async (values: any) => {

        const UpdateRequest = {
            note: values.note,
            done: values.done,
        };

        editRequestMutation.mutate(UpdateRequest)
    };



    return (
        <Form onSubmit={formik.handleSubmit} className="new-request-form">
            {error && <Alert variant="danger">{error}</Alert>}

            <div className="row my-2">
                <div className="col-md-6">
                    <CustomInput
                        label={words.supplier_name}
                        value={data.request.supplier.name}
                        disable={true}
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-md-6">
                    <CustomInput
                        label={words.customer_name}
                        value={data.request.customer_name}
                        disable={true}
                    />
                </div>
                <div className="col-md-6">
                    <CustomInput
                        label={words.customer_mobile_number}
                        value={data.request.customer_phone}
                        disable={true}
                    />
                </div>
            </div>
            <div className="row mt-2">

                <div className="col-md-6">
                    <CustomInput
                        label={words.delivery_place}
                        value={data.request.delivery_place}
                        disable={true}
                    />
                </div>

                <div className="col-md-6">
                    <CustomInput
                        label={words.address_details}
                        value={data.request.address_details}
                        disable={true}
                    />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                    <CustomInput
                        label={words.delivery_time}
                        value={TimeFormat(data.request.delivery_start_time) + ' - ' + TimeFormat(data.request.delivery_end_time)}
                        disable={true}
                    />
                </div>

                <div className="col-md-6">
                    <CustomInput
                        label={words.delivery_date}
                        value={DateFormat(data.request.delivery_date)}
                        disable={true}
                    />
                </div>
            </div>


            <div className='row border m-2'>
                <div className="p-4">
                    <ul className="list-group mb-3 items-list ">
                        {
                            data.items.length === 0 ?
                                <Alert variant='warning'>{words.add_items}</Alert>
                                :
                                data.items.map((option, index) => (
                                    <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                        {option.item_name} * {option.quantity}
                                    </li>
                                ))
                        }
                    </ul>
                </div>
            </div>

            <div className='row mb-3'>
                <TextArea
                    name='message'
                    id='message'
                    label={words.message_body}
                    value={createWhatsAppMessage(data.request, data.items)}
                    disable={true}
                    row={20}
                />
            </div>

            <div className='row mb-3'>
                <TextArea
                    name='note'
                    id='note'
                    label={words.note}
                    value={formik.values.note}
                    onChange={formik.handleChange}
                />
            </div>

            {
                !data.request.canceled && <div className="row my-2">
                    <div className="col-md-6">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={words.done}
                            name='done'
                            id='done'
                            checked={formik.values.done}
                            onChange={formik.handleChange}
                        />
                    </div>
                </div>
            }



            <div className="d-flex justify-content-center mt-4">
                <Button variant="primary" className="responsive-button" size="lg" type="submit">
                    {words.send_request}
                </Button>
            </div>
        </Form>
    );
};
