export const words = {
  // General	כללי
  business_name: 'גיוסי חומרי בנין',
  yes: 'כן',
  no: 'לא',
  loading: 'מעבד',
  something_wrong: 'שגיאה - נא להתקשר לתמיכה',
  page_not_found: 'הדף לא נמצא',
  back_to_home: 'חזרה לראשי',
  address:'כתובת',
  update_by: "עודכן על ידי",
  send_by: "נשלח על ידי",
  note: "הערה",
  From_time: "משעה",
  To_time: "עד שעה",

  address_details:'כתובת',

  // Authentication	זיהוי
  login: 'כניסה',
  logout: 'יציאה',
  username: 'שם משתמש',
  first_name:'שם',
  last_name:'משפחה',
  username_placeholder: 'הכנס שם משתמש',
  username_error: 'לפחות 8 אותיות!',
  password: 'סיסמה',
  password_placeholder: 'הכנס סיסמה',
  password_error: 'לפחות 8 אותיות',
  s2_password: 'אישור סיסמה',
  s2_password_placeholder: 'הכנס סיסמה לאישור',
  current_password: 'סיסמה נוכחית',
  password_dose_not_matched: 'סיסמה שגויה',
  password_invalid: 'הסיסמה הקודמת שגויה',
  role_invalid: 'בחר הרשאות משתמש',
  role_select: 'בחר הרשאה',
  worker: 'עובד',
  manager: 'מנהל',

  // Email	מייל
  email: 'כתובת מייל',
  email_placeholder: 'הכנס מייל',
  email_invalid: 'המייל לא תקין',

  // User	משתמש
  name: 'שם',
  name_required: 'שם משתמשים חובה',
  mobile_number: 'טלפון',
  mobile_number_invalid: 'טלפון שגוי',
  mobile_number_placeholder: 'הכנס מס טלפון',
  status: 'סטטוס',
  last_login: 'כניסה אחרונה',
  deactivate: 'לא פעיל',
  active: 'פעיל',
  role: 'רמה',
  users: 'משתמשים',

  // Actions	פעולות
  edit_action: 'עדכון',
  delete_action: 'מחק',
  save_changes: 'שמור',

  // Company	חברה
  logistics_companies: 'חברות הובלה',
  edit_company_information: 'עדכון פרטי החברות',
  delete_company: 'מחק חברה',
  add_new_company: 'הוסף חברה',
  companies: 'חברות הובלה',

  // Vehicle	רכבים
  vehicle: 'רכב',
  height: 'גובה מקסימלי',
  weight: 'משקל מקסימלי',
  driver_mobile_number: 'טלפון נהג',
  total_vehicles: 'מס רכבים',

  // Lists	רשימות
  user_list: 'רשימת משתמשים',
  add_new_user: 'משתמש חדש',
  add_new_user_btn:'הוסף',
  logistics_companies_list: 'רשימת חברות',
  vehicles_list: 'רשימת משאיות',
  search: 'חיפוש',

  // Messages	הודעות ועידכונים
  login_failed: 'משתמש או סיסמה לא נכונים',
  no_data_available: 'אין נתונים',
  delete_message: 'אישור מחיקה',

  // Buttons
  change_password: 'שינוי סיסמה',
  add_new_vehicle: 'הוסף רכב',
  edit_vehicle_info: 'עדכון רכב',

  // Company details
  company_name: 'שם חברה',
  company_details: 'פרטי חברת ההובלה',
  back: 'חזרה',

  // Error messages
  height_required: 'אנא להכניס גובה',
  weight_required: 'אנא להכניס משקל',
  driver_mobile_required: 'אנא לכניס טלפון נהג',

  // Price
  price_table_list: 'רשימת מחירים',
  add_new_price_table: 'הוסף מחיר',
  price_page: 'מחירים',
  id: 'סידורי',
  from_location: 'מקור',
  to_location: 'יעד',
  price: 'מחיר',
  vehicle_name: 'סוג רכב',
  to: 'ל',
  for: 'מטרה',
  price_invalid: 'מחיר לא תקין',
  fields_required: 'כל השדות חובה',
  select_vehicle: 'בחר רכב',
  add_new_price: 'הוסף מחיר',

  // Order
  add_new_order: 'הוסף הזמנה',
  order: 'הזמנה',
  orders: 'הזמנת הובלה',
  order_id: 'מס הזמנה',
  customer_name: 'לקוח',
  customer_mobile_number: 'מספר הלקוח',
  delivery_place: 'יעד ההובלה',
  delivery_time: 'שעת משלוח',
  delivery_date: 'תאריך',
  delivery_height: 'גובה מבוקש',
  create_date: 'נוצר בתאריך',
  create_time: 'נוצר בשעה',
  total: 'סה"כ',
  done: 'נמסר',
  actions: 'פעולות',
  not_done: 'לא בוצע',
  not_selected: 'לא נבחרה משאית',
  customer_name_required: 'שם לקוח חובה',
  delivery_place_required: 'כתובת יעד - חובה',
  delivery_time_required: 'שעת ההובלה - חובה',
  delivery_date_required: 'תאריך ההובלה - חובה',
  delivery_height_required: 'גובה ההובלה - חובה',
  delivery_items_required: 'נא בחר או ציין את הפריטים למשלוח.',
  vehicle_number: 'מס רכב',
  selectVehicle: 'בחר רכב',
  selectCompany: 'בחר ספק (חברה)',
  invalid_date_range: 'תאריך לא תקין- מותר להכניס שבוע אחורה ועד חודש קדימה',
  date_too_far: 'נא לבחור תאריך פחות מחודש ימים',
  whatsapp_number: 'טלפון',
  add_items: 'הוסף פריט',
  select_item: 'בחר פריט',
  quantity: 'כמות',


  // Supply
  add_new_supplier: 'הוסף ספק',
  supplier_page: 'דף ספקים',

  // Request
  add_new_request: 'הוסף הזמנה',
  request_page: 'הזמנה מספק',
  name_min_length: 'השם חייב להיות 3 אותיות לפחות',
  name_only_letters: 'שם מכיל אותיות ומרווחים בלבד',
  number_required: 'טלפון חובה',
  invalid_number_format: 'מס טלפון חייב להתחיל ב + ומכיל 9 ספרות לפחות',
  update: 'עדכון',
  supplier_name: 'שם ספק',
  messages: 'הודעה',
  cancel_message_alies: 'ביטול הודעה',
  cancel_message: 'בטוח שתרצה לבטל ההזמנה? תשלח הודעה לספק',
  request_id: 'מס הזמנה',
  request: 'הזמנה',
  request_send_by: 'נשלח ע"י',
  request_send_date: 'נוצר ב',
  request_send_time: 'שעה',
  select_supplier: 'בחר ספק',
  message_body: 'ההודעה',
  message_body_required: 'חייב לרשום הודעה',
  send_request: 'שלח',
  cancel_action: 'ביטול',
  whatsapp_confirmation_message: "האם השלמת את הפעולה שלך ב-WhatsApp?",
  cancel_request: "הזמנה בוטלה (body)",

  //reports 
  orders_done:'הזמנות שהושלמו',
  orders_not_done:'הזמנות שלא הושלמו',
  total_orders:'סה"כ הזמנות',
  price_sun:'מחיר כולל',
  start_date:'תאריך התחלה',
  end_date:'תאריך סיום',
  show_not_done_orders:'הצג הזמנות שלא הושלמו',
  total_request: 'בקשה כוללת',
  canceled_request: 'בקשה שבוטלה',

  //item
  items:'פריטים',
  items_list:'רשימת פריטים',
  add_new_items:'הוספת פריט',
  item_name:'שם פריט',

  //materials
  materials:'פריטים',
  
  //report
  report:'דוחות'

};


