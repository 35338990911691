import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { ChangePasswordAction } from '../redux/actions/userAction';

import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';

interface PasswordChangeFormProps {
  onHide: () => void;
}

export const PasswordChangeForm: React.FC<PasswordChangeFormProps> = ({ onHide }) => {
  const { error } = useAppSelector(state => state.global);
  const dispatch = useAppDispatch();

  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorInput, setErrorInput] = useState('');

  let passwordTimeout: NodeJS.Timeout;

  const handleOldPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const oldPassword = event.target.value;
    setOldPassword(oldPassword);
  };

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
  };

  const handleConfirmPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const confirmPassword = event.target.value;
    setConfirmPassword(confirmPassword);

    clearTimeout(passwordTimeout);
    passwordTimeout = setTimeout(() => {
      setErrorInput(password.length < 8 || confirmPassword.length < 8 ? words.username_error : '');
    }, 400);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      setErrorInput(words.password_dose_not_matched);
      return;
    }
    if (oldPassword === password) {
      setErrorInput(words.password_invalid);
      return;
    }

    const data = {
      old_password: oldPassword,
      new_password: password
    };
    dispatch(ChangePasswordAction(data));
    if (!error) {
      onHide();
      document.location.href = '/';
    }

    setPassword('');
    setConfirmPassword('');
    setErrorInput('');
  };

  const isButtonDisabled = password.length < 8 || errorInput !== '' || password !== confirmPassword;

  return (
    <Form onSubmit={handleSubmit}>

      <CustomInput
        label={words.current_password}
        type="password"
        value={oldPassword}
        onChange={handleOldPasswordChange}
        placeholder={words.password_placeholder}
        isPassword
      />

      <CustomInput
        label={words.password}
        type="password"
        value={password}
        onChange={handlePasswordChange}
        placeholder={words.password_placeholder}
        isPassword
        error={password !== confirmPassword ? words.password_dose_not_matched : ''}
      />

      <CustomInput
        label={words.s2_password}
        type="password"
        value={confirmPassword}
        onChange={handleConfirmPasswordChange}
        placeholder={words.s2_password}
        isPassword
        error={errorInput}
      />

      <Button variant="primary" type="submit" disabled={isButtonDisabled}>
        {words.change_password}
      </Button>
    </Form>
  );
};
