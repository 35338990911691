import { ORDER_API } from "../models/order";
import { REQUEST_API } from "../models/request";
import { formatPhoneNumber } from "./mobileNumebr";
import { DateFormat, TimeFormat } from "./time";



export const createWhatsAppMessage = (resp: REQUEST_API.Request, items: any) => {
    let itemsString = "פרטי הפריטים:\n";
    
    items.forEach((record:any) => {
        itemsString += `שם הפריט: ${record.item_name}, כמות: ${record.quantity}\n`;
    });

    return `
        שלום,
        
        אנו רוצים להזמין משלוח לפי הפרטים הבאים:
        
        מספר הבקשה: ${resp.id}
        שם הלקוח: ${resp.customer_name}
        מספר הטלפון של הלקוח: ${formatPhoneNumber(resp.customer_phone)}
        מקום המשלוח: ${resp.delivery_place}
        פרטי הכתובת: ${resp.address_details}
        שעת המשלוח: ${TimeFormat(resp.delivery_start_time)}
        תאריך המשלוח: ${DateFormat(resp.delivery_date)}
        
        ${itemsString}
            
        תודה רבה`;
}