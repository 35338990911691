import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert } from 'react-bootstrap';
import {GetAllOrdersList} from '../redux/services/orderService'
import { FaPlusCircle } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/customModal';
import OrdersListTable from '../components/ordersTable';
import { NewOrderForm } from '../components/addNewOrederForm';


export default function Orders() {

  const [showNewOrder, setShowNewOrder] = useState(false);
  const { data:orders, status, refetch } = useQuery('orders', GetAllOrdersList);
  const navigate = useNavigate();
  
  const handleCloseNewOrderModal = () => {
    setShowNewOrder(false);
    refetch();
  };

  const handleShowNewOrderModal = () => {
    setShowNewOrder(true);
  };

  if (status === 'error' || status === 'idle'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }

  if (status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };


  return (
    <>
        <div className='d-flex justify-content-start mt-2'>
          <Button onClick={handleGoBack}  className="back-button responsive-button">
            {words.back} <FiArrowRight className="back-icon" />
          </Button>
      </div>
     

      <Container fluid className="my-4 actionBar">
        <Button variant="success" size="lg" onClick={handleShowNewOrderModal}>
          <FaPlusCircle /> {words.add_new_order}
        </Button>
      </Container>

      <div className="userTableContainer my-4">
        <div className="title-container">
          <h3 className="title">{words.orders}</h3>
        </div>
        <div className={`TableWrap`}>
          {orders.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<OrdersListTable orders={orders.data} refresh={refetch} />}
        </div>
      </div>

      <CustomModal show={showNewOrder} onHide={handleCloseNewOrderModal} title={words.add_new_order}>
        <NewOrderForm refresh={refetch} data={orders.data}/>
      </CustomModal>
    </>
  );
}