import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Alert } from 'react-bootstrap';
import { FiArrowRight } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { GetTransportPriceList } from '../redux/services/transportServices';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { FaPlusCircle } from 'react-icons/fa';
import CustomModal from '../components/customModal';
import PricesListTable from '../components/pricesTable';
import { AddPriceForm } from '../components/addNewPriceForm';

function ListPrice() {
  const [addNewPriceTableModalShow, setAddNewPriceTableModalShow] = React.useState(false);
  const navigate = useNavigate();

  const { data:priceData, status, refetch } = useQuery('priceData', GetTransportPriceList);

  if (status === 'loading') {
    return <Loading/>;
  }

  if(status === 'error' || status === 'idle'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }
  
  const handleShowNewPriceTableModal = () => {
    setAddNewPriceTableModalShow(true);
  };

  const handleHideNewPriceTableModal = () => {
    setAddNewPriceTableModalShow(false);
    refetch();
  };

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <div className='d-flex justify-content-start mt-2'>
          <Button onClick={handleGoBack} className="back-button responsive-button">
              {words.back} <FiArrowRight className="back-icon" />
          </Button>
      </div>

      <div>
        <div className="d-flex my-4 company-details-header">
          <h2 className="mr-auto ml-auto company-details-title">{words.price_table_list}</h2>
        </div>

        <div className="my-4 actionBar">
          <Button variant="success" className="responsive-button" onClick={handleShowNewPriceTableModal}>
            <FaPlusCircle /> {words.add_new_price_table}
          </Button>
        </div>

        <div className="userTableContainer my-4">
          <div className="title-container">
            <h3 className="title">{words.price_table_list}</h3>
          </div>
          <div className={`TableWrap`}>
            {priceData.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<PricesListTable prices={priceData.data} refresh={refetch} />}
          </div>
        </div>
      </div>

      <CustomModal onHide={handleHideNewPriceTableModal} show={addNewPriceTableModalShow} title={words.add_new_price_table}>
        <AddPriceForm refresh={refetch}/>
      </CustomModal>
    </>
  );
}

export default ListPrice;
