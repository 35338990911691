import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { USERS_ENDPOINT } from '../constants/endpoints';
import { toast } from 'react-toastify';

interface DeleteUserFormProps {
  onHide: () => void;
  id: string;
  username: string;
}

export const DeleteUserForm: React.FC<DeleteUserFormProps> = ({ onHide, id, username }) => {
  const [error, setError] = useState('');

  const deleteUserMutation = useMutation<void, unknown>(() => {
    return apiInstance.delete(`${USERS_ENDPOINT}${id}/`).then(() => {
      onHide();
      toast.info('הפעולה הושלמה בהצלחה');
    });
  }, {
    onError: () => {
      setError(words.something_wrong);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteUserMutation.mutate();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}
      
      <div style={{ marginBottom: '20px' }}>
        <Alert variant="secondary">
          {words.delete_message}: {username}
        </Alert>
      </div>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
