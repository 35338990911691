import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT, REQUEST_ENDPOINT } from '../constants/endpoints';
import { REQUEST_API } from '../models/request';

interface CancelRequestFormProps {
  onHide: () => void;
  request: REQUEST_API.Request;
}

export const CancelRequestForm: React.FC<CancelRequestFormProps> = ({ onHide, request}) => {
  const [error, setError] = useState('');

  const cancelRequestMutation = useMutation<void, unknown, any>(
    (data) => {
        return apiInstance.patch(`${LOGISTICS_ENDPOINT}${REQUEST_ENDPOINT}${request.id}/`, data).then(() => {
            onHide();
        });
    },
    {
        onError: () => {
            setError(words.something_wrong);
        },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let data={
      canceled:true
    }

    const messageBody = `${request.id}#`;
    const encodedMessageBody = encodeURIComponent(messageBody);
    const whatsappUrl = `https://wa.me/${request.supplier.whatsapp_number}?text=${words.cancel_request.replace('(body)', encodedMessageBody)}`;
    window.open(whatsappUrl, '_blank');

    const handleFocus = () => {
        const isDone = window.confirm(words.whatsapp_confirmation_message);
        if (isDone) {
            cancelRequestMutation.mutate(data);
        } else {
            window.removeEventListener('focus', handleFocus);
            return;
            
        }

        window.removeEventListener('focus', handleFocus);
    };

    window.addEventListener('focus', handleFocus);
  };


  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <Alert variant="secondary" className="mt-4">
        {words.cancel_message}: {request.id}
      </Alert>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
