import React from 'react';
import { Link } from 'react-router-dom';
import {words} from '../constants/translate'
import '../assets/CSS/notFoundPage.css';

const NotFoundPage: React.FC = () => {
  return (
    <div className="not-found-page">
      <h1>404</h1>
      <p>{words.page_not_found}</p>
      <Link to="/">{words.back_to_home}</Link>
    </div>
  );
};

export default NotFoundPage