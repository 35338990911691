import {USER_API} from '../models/user'


export function setUser(user: USER_API.User): void {
    localStorage.setItem("user_info", JSON.stringify(user));
}

export function getUser(): USER_API.User | null {
    const storedUser = localStorage.getItem("user_info");
    return storedUser ? JSON.parse(storedUser) : null;
}

export function removeUser(): void {
    localStorage.removeItem("user_info");
}