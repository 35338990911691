import { MainLayout } from '../components/layout'
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Dashboard,
  NotFoundPage,
  Users,
  Logistics,
  LogisticsCompanyDetails,
  ListPrice,
  Orders,
  Suppliers,
  Requests,
  ListItem,
  Report
} from '../screens'
import { getUser } from '../helper/authUtils';

export function PrivateStack() {
  const user = getUser()

  return (

    <MainLayout>
      <BrowserRouter>
        <Routes>
            <Route path="*" element={<NotFoundPage />} />

            {user?.is_superuser || user?.role === 'manager'?
              <>
                <Route index path='/' element={<Dashboard />} />
                <Route path='/users' element={<Users />} />
                <Route path='/logistics' element={<Logistics />} />
                <Route path="/logistics/:id" element={<LogisticsCompanyDetails/>} />
                <Route path="/price" element={<ListPrice/>} />
                <Route path='/orders' element={<Orders/>} />
                <Route path='/suppliers' element={<Suppliers/>} />
                <Route path='/requests' element={<Requests/>} />
                <Route path='/items' element={<ListItem/>} />
                <Route path='/report' element={<Report/>} />

              </>
            : 
              <>
                <Route index path='/' element={<Dashboard />} />
                <Route path='/logistics' element={<Logistics />} />
                <Route path="/logistics/:id" element={<LogisticsCompanyDetails/>} />
                <Route path="/price" element={<ListPrice/>} />
                <Route path='/orders' element={<Orders/>} />
                <Route path='/suppliers' element={<Suppliers/>} />
                <Route path='/requests' element={<Requests/>} />
                <Route path='/items' element={<ListItem/>} />
              </>
            }
          
        </Routes>
      </BrowserRouter>
    </MainLayout>

  );
}