import axiosInstance from '../../helper/axiosInstance'

import { LOGISTICS_ENDPOINT, SUPPLIER_ENDPOINT } from '../../constants/endpoints'



export const GetAllSupplier = async () => {
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+SUPPLIER_ENDPOINT);
    return response;
};
