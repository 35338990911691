import React, { useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import apiInstance from '../helper/axiosInstance';
import { CustomInput } from './input';
import { SUPPLIER_API } from '../models/supplier';
import { words } from '../constants/translate';
import { useMutation } from 'react-query';
import { LOGISTICS_ENDPOINT, SUPPLIER_ENDPOINT } from '../constants/endpoints';

interface EditSupplierFormProps {
    supplier: SUPPLIER_API.Supplier
    onHide: () => void;

}

const EditSupplierForm: React.FC<EditSupplierFormProps> = ({ supplier, onHide }) => {
    const [name, setName] = useState(supplier.name);
    const [whatsappNumber, setWhatsappNumber] = useState(supplier.whatsapp_number);
    const [is_active, setIsActive] = useState<boolean>(supplier.is_active);
    const [nameError, setNameError] = useState<string | null>(null);
    const [numberError, setNumberError] = useState<string | null>(null);
    const [error, setError] = useState('');


    const createSupplierMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.patch(LOGISTICS_ENDPOINT + SUPPLIER_ENDPOINT + `${supplier.id}/`, data).then(() => {
                onHide();
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        let data ={
            name: name,
            whatsapp_number:whatsappNumber,
            is_active:is_active,
        }
        createSupplierMutation.mutate(data)

        setName('')
        setWhatsappNumber('')
        
    };
    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            <Row>
                <Col md={6}>
                    <CustomInput
                        label={words.name}
                        value={name}
                        name="name"
                        onChange={(e) => setName(e.target.value)}
                        required
                        error={nameError}
                    />
                </Col>
                <Col md={6}>
                    <CustomInput
                        label={words.whatsapp_number}
                        value={whatsappNumber}
                        name="whatsapp_number"
                        type='number'
                        onChange={(e) => setWhatsappNumber(e.target.value)}
                        required
                        error={numberError}
                    />
                </Col>
            </Row>
            <Row className="mt-3">
                <Col md={2}>
                    <Form.Check
                        type="checkbox"
                        label={words.active}
                        checked={is_active}
                        name="is_active"
                        onChange={(e) => setIsActive(!is_active)}
                    />
                </Col>
            </Row>
            <Row className="mt-4">
                <Col className="d-flex justify-content-center">
                    <Button variant="primary" className="responsive-button" type="submit">
                        {words.save_changes}
                    </Button>
                </Col>
            </Row>
        </Form>
    );
};

export default EditSupplierForm
