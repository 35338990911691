import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import apiInstance from '../helper/axiosInstance';
import { LOGISTICS_ENDPOINT, TRANSPORT_PRICE_ENDPOINT } from '../constants/endpoints';
import { PRICE_API } from '../models/price';
import { cites } from '../constants/cites';
import { AutocompleteInput } from './autoCompleteInput';

interface EditPriceFormProps {
  onHide: () => void;
  price: PRICE_API.Price
}

export const EditPriceForm: React.FC<EditPriceFormProps> = ({ onHide, price }) => {
  const [error, setError] = useState('');
  const [fromLocation, setFromLocation] = useState(price.from_location);
  const [toLocation, setToLocation] = useState(price.to_location);

  const [newPrice, setNewPrice] = useState(price.price.toString());

  const editPriceMutation = useMutation<void, unknown, any>(
    (data) => {
      return apiInstance.patch(`${LOGISTICS_ENDPOINT}${TRANSPORT_PRICE_ENDPOINT}${price.id}/`, data).then(() => {
        onHide();
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!fromLocation || !toLocation ) {
      setError(words.fields_required);
      return;
    }

    if (!/^\d{1,4}(\.\d{1,2})?$/.test(newPrice)) {
      setError(words.price_invalid);
      return;
    }

    const updatedPrice = {
      from_location: fromLocation,
      to_location: toLocation,
      price: parseFloat(newPrice),
    };

    editPriceMutation.mutate(updatedPrice);

    setFromLocation('');
    setToLocation('');
    setNewPrice('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="row">
        <div className="col-md-6">
          <AutocompleteInput
            suggestions={cites}
            label={words.from_location}
            type="text"
            value={fromLocation}
            onSuggestionSelect={(value) => setFromLocation(value)}
            />

        </div>
        <div className="col-md-6">
          <AutocompleteInput
            suggestions={cites}
            label={words.to_location}
            type="text"
            value={toLocation}
            onSuggestionSelect={(value) => setToLocation(value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={words.price}
            type="text"
            value={newPrice}
            onChange={(event) => setNewPrice(event.target.value)}
          />
        </div>
      </div>

      <Button variant="primary" className="mt-4" type="submit">
        {words.save_changes}
      </Button>
    </Form>
  );
};
