import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { COMPANY_API } from '../models/company';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';

interface EditCompanyFormProps {
  onHide: () => void;
  company: COMPANY_API.Company;
}

export const EditCompanyForm: React.FC<EditCompanyFormProps> = ({ onHide, company }) => {
  const [error, setError] = useState('');
  const [name, setName] = useState(company.name);
  const [mobileNumber1, setMobileNumber1] = useState(company.mobile_number_1);
  const [mobileNumber2, setMobileNumber2] = useState(company.mobile_number_2);
  const [active, setActive] = useState(company.is_active);

  const editCompanyMutation = useMutation<void, unknown, COMPANY_API.Company>(
    (data) => {
      return apiInstance.patch(LOGISTICS_ENDPOINT + `logistics-companies/${company.id}/`, data).then(() => {
        onHide();
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleActiveChange = () => {
    setActive(!active);
  };

  const handleMobileNumber2Change = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    setMobileNumber2(value);
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name) {
      setError(words.name_required);
      return;
    }

    if (mobileNumber1.length < 3) {
      setError(words.mobile_number_invalid);
      return;
    }

    const updatedCompany: COMPANY_API.Company = {
      ...company,
      name,
      mobile_number_1: mobileNumber1,
      mobile_number_2: mobileNumber2,
      is_active: active,
    };

    editCompanyMutation.mutate(updatedCompany);

    setName('');
    setMobileNumber1('');
    setMobileNumber2('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={words.name}
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
        </div>
        <div className="col-md-6">
          <CustomInput
            label={`${words.mobile_number} 1`}
            type="text"
            value={mobileNumber1}
            onChange={(event) => setMobileNumber1(event.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={`${words.mobile_number} 2`}
            type="text"
            value={mobileNumber2}
            onChange={handleMobileNumber2Change}
          />
        </div>
        <div className="col-md-2 m-4">
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="activeCheckbox"
              checked={active}
              onChange={handleActiveChange}
            />
            <label className="form-check-label" htmlFor="activeCheckbox">
              {words.active}
            </label>
          </div>
        </div>
      </div>

      <Button variant="primary" className="mt-4" type="submit">
        {words.save_changes}
      </Button>
    </Form>
  );
};
