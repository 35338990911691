

import React from 'react';
import { Modal } from 'react-bootstrap';

interface CustomModalProps {
  show: boolean;
  onHide: () => void;
  title: string;
  size?: 'lg'| 'xl';
  children: React.ReactNode;
}

export const CustomModal: React.FC<CustomModalProps> = ({ show, onHide, title, children, size='lg' }) => {
  return (
    <Modal show={show} onHide={onHide} size={size}>
      <div className="custom-modal-header">
        <h5 className="modal-title-rtl">{title}</h5>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">{children}</div>
    </Modal>
  );
};

export default CustomModal;