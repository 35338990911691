import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';
import { toast } from 'react-toastify';

interface DeleteVehicleFormProps {
  onHide: () => void;
  id: string;
  name: string;
}

export const DeleteVehicleForm: React.FC<DeleteVehicleFormProps> = ({ onHide, id, name }) => {
  const [error, setError] = useState('');

  const deleteVehicleMutation = useMutation<void, unknown>(() => {
    return apiInstance.delete(`${LOGISTICS_ENDPOINT}vehicle/${id}/`).then(() => {
      onHide();
      toast.info('הפעולה הושלמה בהצלחה');
    });
  }, {
    onError: () => {
      setError(words.something_wrong);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteVehicleMutation.mutate();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <div style={{ marginBottom: '20px' }}>
        <Alert variant="secondary">
          {words.delete_message}: {name}
        </Alert>
      </div>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
