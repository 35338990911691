import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';
import { toast } from 'react-toastify';

interface DeleteUserFormProps {
  onHide: () => void;
  id: string;
  company_name: string;
}

export const DeleteCompanyForm: React.FC<DeleteUserFormProps> = ({ onHide, id, company_name }) => {
  const [error, setError] = useState('');

  const deleteUserMutation = useMutation<void, unknown>(() => {
    return apiInstance.delete(`${LOGISTICS_ENDPOINT}logistics-companies/${id}/`).then(() => {
      onHide();
      toast.info('הפעולה הושלמה בהצלחה');
    });
  }, {
    onError: () => {
      setError(words.something_wrong);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteUserMutation.mutate();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <Alert variant="secondary" className="mt-4">
        {words.delete_message}: {company_name}
      </Alert>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
