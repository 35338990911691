import React, { useState } from 'react';
import { Table, Form, InputGroup, Pagination, Col, Row } from 'react-bootstrap';
import { words } from '../constants/translate';
import '../assets/CSS/report.css'
import { REQUEST_API } from '../models/request';
import { DateFormat, TimeFormat } from '../helper/time';


interface OrdersListTableProps {
    requests: REQUEST_API.Request[];
}

const RequestReport: React.FC<OrdersListTableProps> = ({ requests }) => {
    console.log(requests)
    const getStartOfMonth = ():string => {
        const date = new Date();
        date.setDate(1);
        return date.toISOString().slice(0, 10);
    };

    const getEndOfMonth = () :string => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return date.toISOString().slice(0, 10);
    };


    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [startDate, setStartDate] = useState<string>(getStartOfMonth());
    const [endDate, setEndDate] = useState<string>(getEndOfMonth());



    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredRequest = [...requests].sort((a: any, b: any) => b?.id - a?.id).filter((record: any) => {
        const searchValue = searchTerm.toLowerCase();
        return (            
             record.request.supplier.name.toLowerCase().includes(searchValue)||
             record.request.supplier.whatsapp_number.toLowerCase().includes(searchValue)||
             record.request.created_by.username.toLowerCase().includes(searchValue)
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedOrders = filteredRequest.slice(indexOfFirstItem, indexOfLastItem);



    const dateFilteredRequests = filteredRequest.filter((request:any) => {
        const RequestDate = new Date(request.request.create_date);
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateCondition = (!startDate || RequestDate >= start) && (!endDate || RequestDate <= end);
        return dateCondition;
    });

    const totalRequest = dateFilteredRequests.length;
    const canceledRequests = dateFilteredRequests.filter(request => request.canceled).length;

    return (
        <>
            <div className="d-flex justify-content-around my-4">
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.total_request}</h5>
                        <p className="report-card-text">{totalRequest}</p>
                    </div>
                </div>
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.canceled_request}</h5>
                        <p className="report-card-text">{canceledRequests}</p>
                    </div>
                </div>
            </div>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </Form.Group>


                <Row className='m-3'>
                    <Form.Group as={Col} md={3} controlId="startDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.start_date}</Form.Label>
                        <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md={3} controlId="endDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.end_date}</Form.Label>
                        <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>

                </Row>

            </Form>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>{words.request_id}</th>
                        <th>{words.request_send_by}</th>
                        <th>{words.supplier_name}</th>
                        <th>{words.delivery_date}</th>
                        <th>{words.delivery_time}</th>
                        <th>{words.request_send_date}</th>
                        <th>{words.request_send_time}</th>
                    </tr>
                </thead>
                <tbody>
                    {dateFilteredRequests.map((request: any) => (
                        <tr key={request.request.id} className={request.canceled ? 'redRow' : 'greenRow'}>
                            <td>{request.request.id}</td>
                            <td>{request.request.created_by.username}</td>
                            <td>{request.request.supplier.name}</td>
                            <td>{DateFormat(request.request.delivery_date)}</td>
                            <td>{TimeFormat(request.request.delivery_start_time)+'-'+ TimeFormat(request.request.delivery_end_time)}</td>
                            <td>{DateFormat(request.request.create_date)}</td>
                            <td>{TimeFormat(request.request.create_time)}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination>
                    {Array.from({ length: Math.ceil(paginatedOrders.length / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>

            <div className="agenda-boxes">
                <div className="agenda-box">
                    <div className="box redRow"></div>
                    <div className="box-title">{words.canceled_request}</div>
                </div>
                <div className="agenda-box">
                    <div className="box greenRow"></div>
                    <div className="box-title">{words.done}</div>
                </div>
            </div>
        </>

    );
};

export default RequestReport;
