import React, { useState } from 'react';
import { Table, Form, InputGroup, Pagination, Button } from 'react-bootstrap';
import { words } from '../constants/translate';
import { FaEdit, FaTrash } from 'react-icons/fa';
import CustomModal from './customModal';
import { ORDER_API } from '../models/order'
import { DeleteOrderForm } from './deleteOrderForm';
import { EditOrderForm } from './editOrderForm';
import { getUser } from '../helper/authUtils';
import { DateFormat, TimeFormat } from '../helper/time';


interface OrdersListTableProps {
    orders: ORDER_API.Data[];
    refresh: () => any;
}

const OrdersListTable: React.FC<OrdersListTableProps> = ({ orders, refresh }) => {
    const user = getUser()
    const [showEditOrderModal, setShowEditOrderModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [DataElement, setDataElement] = useState<ORDER_API.Data | null>(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;


    const handleShowEditOrderModal = (object: ORDER_API.Data) => {
        setShowEditOrderModal(true);
        setDataElement(object);
    };

    const handleHideEditOrderModal = () => {
        setShowEditOrderModal(false);
        refresh();
    };

    const handleShowDeleteOrderModal = (object: ORDER_API.Data) => {
        setShowDeleteModal(true);
        setDataElement(object);
    };

    const handleHideDeleteOrderModal = () => {
        setShowDeleteModal(false);
        refresh();
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1);
    };

    const filteredOrders = [...orders].sort((a: any, b: any) => b?.id - a?.id).filter((data: any) => {
        const searchValue = searchTerm.toLowerCase(); 
        return (
            data.order.customer_name.toLowerCase().includes(searchValue) ||
            data.order.delivery_place.toLowerCase().includes(searchValue) ||
            data.order.delivery_time.includes(searchValue) ||
            data.order.delivery_date.includes(searchValue) ||
            data.order.vehicle_id?.name.includes(searchValue) || 
            data.order.vehicle_id?.company.name.includes(searchValue)
        );
    });

    const totalItems = filteredOrders.length;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);


    return (
        <>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>
            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>{words.order_id}</th>
                        <th>{words.customer_name}</th>
                        <th>{words.customer_mobile_number}</th>
                        <th>{words.delivery_place}</th>
                        <th>{words.delivery_time}</th>
                        <th>{words.delivery_date}</th>
                        <th>{words.delivery_height}</th>
                        <th>{words.price}</th>
                        <th>{words.vehicle}</th>
                        <th>{words.done}</th>
                        <th>{words.actions}</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedOrders.map((data:ORDER_API.Data) => (
                        <tr key={data.order.id} className={data.order.vehicle_id ? data.order.done ? 'greenRow' : 'redRow' : 'orangeRow'}>
                            <td>{data.order.id}</td>
                            <td>{data.order.customer_name}</td>
                            <td>{data.order.customer_phone}</td>
                            <td>{data.order.delivery_place}</td>
                            <td>{TimeFormat(data.order.delivery_start_time) + ' - ' + (data.order.delivery_end_time ? TimeFormat(data.order.delivery_end_time) : 'N/A')}</td>
                            <td>{DateFormat(data.order.delivery_date)}</td>
                            <td>{data.order.delivery_height}</td>
                            <td>{data.order.delivery_price}</td>
                            <td>{data.order.vehicle_id ? data.order.vehicle_id?.name +' || '+ data.order.vehicle_id?.company.name : words.not_selected}</td>
                            <td>{data.order.done ? words.yes : words.no}</td>
                            <td>
                                <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditOrderModal(data)}>
                                    <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                                </Button>
                                {
                                    user?.role === 'manager' || user?.is_superuser ?
                                        <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteOrderModal(data)}>
                                            <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                                        </Button>
                                        :
                                        null
                                }

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination>
                    {Array.from({ length: Math.ceil(paginatedOrders.length / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>
            <div className="agenda-boxes">
                <div className="agenda-box">
                    <div className="box redRow"></div>
                    <div className="box-title">{words.not_done}</div>
                </div>
                <div className="agenda-box">
                    <div className="box greenRow"></div>
                    <div className="box-title">{words.done}</div>
                </div>
                <div className="agenda-box">
                    <div className="box orangeRow"></div>
                    <div className="box-title">{words.not_selected}</div>
                </div>
            </div>
            <CustomModal show={showEditOrderModal} onHide={handleHideEditOrderModal} title={words.edit_action + ' ' + words.order + ' ' + DataElement?.order.customer_name}>
                <EditOrderForm onHide={handleHideEditOrderModal} object={DataElement!} />
            </CustomModal>

            <CustomModal show={showDeleteModal} onHide={handleHideDeleteOrderModal} title={words.delete_action + ' ' + words.order + ' ' + DataElement?.order.customer_name}>
                <DeleteOrderForm onHide={handleHideDeleteOrderModal} object={DataElement!} />
            </CustomModal>
        </>
    );
};

export default OrdersListTable;
