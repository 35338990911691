import React from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import apiInstance from '../helper/axiosInstance';
import { CustomInput } from './input';
import { Formik, useFormik } from 'formik';
import * as Yup from 'yup';
import { useMutation } from 'react-query';
import { LOGISTICS_ENDPOINT, SUPPLIER_ENDPOINT } from '../constants/endpoints';
import { formatPhoneNumber } from '../helper/mobileNumebr';
import { words } from '../constants/translate';
import { toast } from 'react-toastify';

interface AddNewSupplierFormProps {
    refresh: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required(words.fields_required),
  whatsapp_number: Yup.string().required(words.fields_required).min(8,words.mobile_number_invalid).typeError(words.mobile_number_invalid),
});

const AddNewSupplierForm: React.FC<AddNewSupplierFormProps> = ({ refresh }) => {
  const createSupplierMutation = useMutation<void, unknown, any>(
    (data) => {
      return apiInstance.post(LOGISTICS_ENDPOINT + SUPPLIER_ENDPOINT, data).then(() => {
        refresh();
        toast.success('הפעולה הושלמה בהצלחה');
      });
    },
    {
      onError: () => {
        formik.setStatus(words.something_wrong);
      },
    }
  );

  const formik = useFormik({
    initialValues: {
      name: '',
      whatsapp_number: '',
      is_active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const data = {
        ...values,
        whatsapp_number: formatPhoneNumber(values.whatsapp_number),
      };

      createSupplierMutation.mutate(data);
      formik.resetForm();
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit}>
      {formik.status && <Alert variant="danger">{formik.status}</Alert>}
      <Row>
        <Col md={6}>
          <CustomInput
            label={words.supplier_name}
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.name && formik.errors.name}
          />
        </Col>
        <Col md={6}>
          <CustomInput
            label={words.whatsapp_number}
            name="whatsapp_number"
            type='text'
            value={formik.values.whatsapp_number}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.whatsapp_number && formik.errors.whatsapp_number}
          />
        </Col>
      </Row>
      <Row className="mt-3">
        <Col md={2}>
          <Form.Check
            type="checkbox"
            label={words.active}
            name="is_active"
            checked={formik.values.is_active}
            onChange={formik.handleChange}
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col className="d-flex justify-content-center">
          <Button variant="primary" className="responsive-button" type="submit">
            {words.add_new_supplier}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddNewSupplierForm;
