import React, { useState, useRef, useEffect, HTMLProps } from 'react';
import { Form, ListGroup } from 'react-bootstrap';

interface AutocompleteInputProps extends Omit<HTMLProps<HTMLInputElement>, 'onChange'> {
  label: string;
  value:string;
  isRTL?: boolean;
  suggestions: string[];
  showListOnClick?: boolean;
  matchedRequired?:boolean
  onSuggestionSelect?: (value: any) => void;  
  externalOnBlur?: (e: any) => void;
  name?: string;
  error?: string | false | undefined | null;
  disable?: boolean;
}

export const AutocompleteInput: React.FC<AutocompleteInputProps> = ({
  label,
  value,
  isRTL = true,
  suggestions,
  showListOnClick = true,
  matchedRequired= true,
  error,
  disable = false,
  onSuggestionSelect,
  externalOnBlur,
  name,
  ...rest
}) => {
  const [filteredSuggestions, setFilteredSuggestions] = useState<string[]>([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState<number>(0);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [InternalValue, setInternalValue] = useState<string>(value);

  const listGroupRef = useRef<HTMLDivElement | null>(null);
  const style: any = isRTL ? { textAlign: 'right' } : { textAlign: 'left' };


  useEffect(() => {
    if (listGroupRef.current) {
      const rect = listGroupRef.current.getBoundingClientRect();
      const spaceBelow = window.innerHeight - rect.top;

      listGroupRef.current.style.maxHeight = `${spaceBelow}px`;
      listGroupRef.current.style.overflowY = 'auto';
    }
  }, [showSuggestions, filteredSuggestions]);

  const uniqueSuggestions = Array.from(new Set(suggestions)); // Ensure uniqueness

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const userInput = e.target.value;
    setInternalValue(userInput);
    const filtered = uniqueSuggestions.filter((suggestion) =>
      suggestion.toLowerCase().includes(userInput.toLowerCase())
    );

    setFilteredSuggestions(filtered);
    setShowSuggestions(true);
    setActiveSuggestionIndex(0);
    if (onSuggestionSelect) {
      onSuggestionSelect(userInput);
    }
  };


  const onSuggestionClick = (suggestion: string) => {
    setInternalValue(suggestion);
    setShowSuggestions(false);
    setFilteredSuggestions([]);
    
    if (onSuggestionSelect) {
      onSuggestionSelect(suggestion);
    }
  };

  const handleFocus = () => {   
    if (showListOnClick) {  
      setFilteredSuggestions(uniqueSuggestions);
      setShowSuggestions(true);
    }
  };
  
  const _handleBlur = (e: any) => {

    if (externalOnBlur) {
      externalOnBlur(e);
    }

    setShowSuggestions(false);
    if(matchedRequired){
      const matched = suggestions.includes(value);
      
      if (!matched) {
        setInternalValue('');
      }
    }
  };

  return (
    <Form.Group controlId={rest.id} className="mb-3 position-relative">
      <Form.Label style={style}>{label}</Form.Label>
      <Form.Control
        name={name}
        value={InternalValue}
        className={`form-control ${error ? 'is-invalid' : ''}`}
        onChange={onInputChange}
        onFocus={handleFocus}
        onBlur={(e) => _handleBlur(e)}
        disabled={disable}
        autoComplete="off"
      />
      {showSuggestions && filteredSuggestions.length > 0 && (
        <ListGroup ref={listGroupRef} style={{ position: 'absolute', width: '100%', zIndex: 1000 }}>
          {filteredSuggestions.map((suggestion, index) => (
            <ListGroup.Item
              key={index}
              action
              active={index === activeSuggestionIndex}
              onMouseEnter={()=>setActiveSuggestionIndex(index)}
              onMouseDown={() => onSuggestionClick(suggestion)} 
              onClick={() => onSuggestionClick(suggestion)}
            >
              {suggestion}
            </ListGroup.Item>
          ))}
        </ListGroup>
      )}
      {error && <div className="invalid-feedback">{error}</div>}
    </Form.Group>
  );
};
