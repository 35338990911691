import { createAsyncThunk } from '@reduxjs/toolkit';
import { USER_API } from '../../models/user'

import { 
  loginService,
  logoutService, 
  ChangePasswordService } from '../services/userService'

export const loginAction = createAsyncThunk(
  'login',
  async (data: USER_API.Login, thunkAPI) => {
    try {
      return await loginService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);


export const LogoutAction = createAsyncThunk(
  'logout',
  async (_, thunkAPI) => {
    try {
      return await logoutService();
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);

export const ChangePasswordAction = createAsyncThunk(
  'changePassword',
  async (data: USER_API.changePassword, thunkAPI) => {
    try {
      return await ChangePasswordService(data);
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response)
    }
  },
);