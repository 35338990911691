import React, { ChangeEvent, HTMLProps } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import '../assets/CSS/input.css';

interface CustomTextAreaProps extends Omit<HTMLProps<HTMLTextAreaElement>, 'onChange' | 'value'> {
    label: string;
    isRTL?: boolean;
    error?: string | false | undefined | null;
    disable?: boolean;
    onChange?: (event: ChangeEvent<HTMLTextAreaElement>) => void;
    value?: string | number;
    row?:number
  }
  

export const TextArea: React.FC<CustomTextAreaProps> = ({
  label,
  isRTL = true,
  error,
  disable = false,
  row = 2,
  ...rest
}) => {

  const labelStyle: any = isRTL ? { textAlign: 'right' } : { textAlign: 'left' };
  const inputStyle: any = { direction: isRTL ? 'RTL' : 'LTR' };

  return (
    <Form.Group style={labelStyle} controlId={rest.id} className="mb-3 position-relative">
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="textarea"
        className={`form-control custom-input ${error ? 'is-invalid' : ''}`}
        onChange={rest.onChange}
        value={rest.value}
        name={rest.name}
        placeholder={rest.placeholder}
        style={inputStyle}
        disabled={disable}
        rows={row}
      />
      {error && <div className="invalid-feedback">{error}</div>}
    </Form.Group>
  );
};