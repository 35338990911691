import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaEdit, FaTrash, FaCaretUp, FaCaretDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import '../assets/CSS/table.css';
import CustomModal from './customModal';
import { words } from '../constants/translate';
import { DeleteCompanyForm } from './deleteCompanyForm';
import { EditCompanyForm } from './editComapnyForm';
import { COMPANY_API } from '../models/company';

interface LogisticsCompaniesTableProps {
  logisticsCompanies: COMPANY_API.Company[];
  refresh: () => any
}

export const LogisticsCompaniesTable: React.FC<LogisticsCompaniesTableProps> = ({ logisticsCompanies, refresh }) => {

  const [targetCompany, setTargetCompany] = useState<COMPANY_API.Company | null>(null);
  const [showEditCompanyModal, setShowEditCompanyModal] = useState(false);
  const [showDeleteCompanyModal, setShowDeleteCompanyModal] = useState(false);
  const [targetID, setTargetID] = useState('');
  const [companyNameTarget, setCompanyNameTarget] = useState('');
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc');
  const [sortedColumn, setSortedColumn] = useState<'name' | ''>('');

  const navigate = useNavigate();

  const handleShowEditCompanyModal = (object: COMPANY_API.Company) => {
    setShowEditCompanyModal(true);
    setTargetCompany(object);
  };

  const handleHideEditCompanyModal = () => {
    setShowEditCompanyModal(false);
    refresh()
  };

  const handleShowDeleteCompanyModal = (id: string, name: string) => {
    setShowDeleteCompanyModal(true);
    setTargetID(id);
    setCompanyNameTarget(name);
  };

  const handleHideDeleteCompanyModal = () => {
    setShowDeleteCompanyModal(false);
    setTargetID('');
    setCompanyNameTarget('');
    refresh()
  };

  const handleRowClick = (id: string) => {
    navigate(`/logistics/${id}`);
  };

  const handleSort = (column: 'name' | '') => {
    if (column === sortedColumn) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedColumn(column);
      setSortOrder('asc');
    }
  };

  const sortedCompanies = [...logisticsCompanies].sort((a, b) => {
    if (sortedColumn === 'name') {
      const nameA = a.name.toLowerCase();
      const nameB = b.name.toLowerCase();
      return sortOrder === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
    }
    return 0;
  });

  const getSortArrows = (column: 'name' | '') => {
    const isSortedColumn = sortedColumn === column;
    const isAsc = sortOrder === 'asc';

    const upArrow = isSortedColumn && isAsc ? <FaCaretUp /> : <FaCaretUp style={{ opacity: 0.3 }} />;
    const downArrow = isSortedColumn && !isAsc ? <FaCaretDown /> : <FaCaretDown style={{ opacity: 0.3 }} />;

    return (
      <div className="flex-center">
        <div className="mr-2">
          {upArrow}
        </div>
        <div>
          {downArrow}
        </div>
      </div>
    );
  };

  return (
    <>
      <Table striped bordered hover responsive className="custom-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('name')} className="sort-header">
              <div className="flex-center">
                {words.company_name} {getSortArrows('name')}
              </div>
            </th>
            <th>{words.mobile_number} 1</th>
            <th>{words.mobile_number} 2</th>
            <th>{words.actions}</th>
          </tr>
        </thead>
        <tbody>
          {sortedCompanies.map((company) => (
            <tr
              key={company.id}
              className={company.is_active ? 'greenRow' : 'redRow'}
            >
              <td className='customLink' onClick={() => handleRowClick(company.id)}>{company.name}</td>
              <td style={{ direction: 'ltr' }}>{company.mobile_number_1}</td>
              <td style={{ direction: 'ltr' }}>{company.mobile_number_2}</td>
              <td>
                <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditCompanyModal(company)}>
                  <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                </Button>

                <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteCompanyModal(`${company.id}`, company.name)}>
                  <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="agenda-boxes">
        <div className="agenda-box">
          <div className="box redRow"></div>
          <div className="box-title">{words.active}</div>
        </div>
        <div className="agenda-box">
          <div className="box greenRow"></div>
          <div className="box-title">{words.deactivate}</div>
        </div>
      </div>

      <CustomModal show={showEditCompanyModal} onHide={handleHideEditCompanyModal} title={`${words.edit_company_information} ${targetCompany?.name}`}>
        <EditCompanyForm onHide={handleHideEditCompanyModal} company={targetCompany!} />
      </CustomModal>

      <CustomModal show={showDeleteCompanyModal} onHide={handleHideDeleteCompanyModal} title={`${words.delete_company} ${companyNameTarget}`}>
        <DeleteCompanyForm onHide={handleHideDeleteCompanyModal} id={targetID} company_name={companyNameTarget} />
      </CustomModal>
    </>
  );
};
