import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT,TRANSPORT_PRICE_ENDPOINT } from '../constants/endpoints';
import { PRICE_API } from '../models/price';
import { toast } from 'react-toastify';

interface DeletePriceFormProps {
  onHide: () => void;
  price:PRICE_API.Price
}

export const DeletePriceForm: React.FC<DeletePriceFormProps> = ({ onHide,price }) => {
  const [error, setError] = useState('');

  const deleteUserMutation = useMutation<void, unknown>(() => {
    return apiInstance.delete(`${LOGISTICS_ENDPOINT}${TRANSPORT_PRICE_ENDPOINT}${price.id}/`).then(() => {
      onHide();
      toast.info('הפעולה הושלמה בהצלחה');
    });
  }, {
    onError: () => {
      setError(words.something_wrong);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteUserMutation.mutate();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <Alert variant="secondary" className="mt-4">
        {words.delete_message}: {words.delete_action + " " +price?.from_location + " " + words.to+ " " + price?.to_location+ " " + words.for+ " " + price?.vehicle.name }
      </Alert>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
