import React, { HTMLProps, useState } from 'react';
import { Form, Button, InputGroup } from 'react-bootstrap';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import '../assets/CSS/input.css';

interface CustomInputProps extends HTMLProps<HTMLInputElement> {
  label: string;
  isRTL?: boolean;
  isPassword?: boolean;
  value?: any;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  name?: string;
  error?: string | false | undefined | null;
  disable?:boolean,
  type?:string
}

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  isRTL = true,
  isPassword,
  error,
  disable=false,
  type='text',
  ...rest
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const labelStyle: any = isRTL ? { textAlign: 'right' } : { textAlign: 'left' };
  const inputStyle: any = type === 'number' ? { direction: 'LTR' } : { direction: 'RTL' };

  return (
    <Form.Group style={labelStyle} controlId={rest.id} className="mb-3 position-relative">
      <Form.Label>{label}</Form.Label>
      <InputGroup>
        <div style={{ position: 'relative', width: '100%' }}>
          <Form.Control
            className={`form-control custom-input ${error ? 'is-invalid' : ''}`}
            type={isPassword && !showPassword ? 'password' : 'text'}
            onChange={rest.onChange}
            onBlur={rest.onBlur}
            value={rest.value}
            name={rest.name}
            placeholder={rest.placeholder}
            style={{ ...inputStyle, zIndex: 2, width: '100%' }}
            disabled={disable}
            autoComplete="off"
          />
          {isPassword && (
            <div
              style={{
                zIndex: 3,
                position: 'absolute',
                left: '10px',
                top: error?'30%':"50%",
                transform: 'translateY(-50%)',
              }}
            >
              <Button
                variant="link"
                onClick={togglePasswordVisibility}
                style={{
                  color: '#6c757d',
                  backgroundColor: 'transparent',
                  border: 'none',
                  padding: 0,
                  cursor: 'pointer',
                }}
              >
                {showPassword ? <FaEyeSlash /> : <FaEye />}
              </Button>
            </div>
          )}
          {error && <div className="invalid-feedback">{error}</div>}
        </div>
      </InputGroup>
      
    </Form.Group>
  );
};