import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import '../assets/CSS/cards.css';

interface CardWithOverlayProps {
  imageUrl: any;
  title: string;
  link: string;
}

const CardWithOverlay: React.FC<CardWithOverlayProps> = ({ imageUrl, title, link }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <Link to={link} style={{ textDecoration: 'none' }} className='m-3'>
      <Card className={'cardContainer mb-4 position-relative'} onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
        <div style={{ backgroundImage: `url(${imageUrl})` }} className={'cardBackground'} />
        <div style={{ backgroundColor: isHovered ? 'rgba(0, 0, 0, 0.5)' : 'transparent', opacity: isHovered ? 1 : 0 }} className={'overlay'} />
        <h2
          className={'fs-2 title'}
          style={{
            bottom: isHovered ? '50%' : '0',
            color: isHovered ? '#fff' : '#000',
            background: isHovered ? 'rgba(0, 0, 0, 0.8)' : '#fff'
          }}
        >
          {title}
        </h2>
      </Card>
    </Link>
  );
};

export default CardWithOverlay;

