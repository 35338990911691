import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { STORE_ENDPOINT,ORDER_ENDPOINT } from '../constants/endpoints';
import { ORDER_API } from '../models/order';
import { toast } from 'react-toastify';

interface DeleteOrderFormProps {
  onHide: () => void;
  object: ORDER_API.Data
}

export const DeleteOrderForm: React.FC<DeleteOrderFormProps> = ({ onHide, object }) => {
  const [error, setError] = useState('');

  const deleteUserMutation = useMutation<void, unknown>(() => {
    return apiInstance.delete(`${STORE_ENDPOINT}${ORDER_ENDPOINT}${object.order.id}/`).then(() => {
      onHide();
      toast.info('הפעולה הושלמה בהצלחה');
    });
  }, {
    onError: () => {
      setError(words.something_wrong);
    }
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    deleteUserMutation.mutate();
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <Alert variant="secondary" className="mt-4">
        {words.delete_message} {words.order}: {object.order.id}
      </Alert>

      <Button
        variant="danger"
        style={{ marginTop: '20px', width: '100%' }}
        type="submit"
      >
        {words.delete_action}
      </Button>
    </Form>
  );
};
