import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import '../assets/CSS/vehicleTable.css';
import { FaCaretUp, FaCaretDown, FaEdit, FaTrash } from 'react-icons/fa';
import CustomModal from './customModal';
import { COMPANY_API } from '../models/company';
import { words } from '../constants/translate';
import { DeleteVehicleForm } from './deleteVehicleForm';
import { EditVehicleForm } from './editVehicleForm';
import { getUser } from '../helper/authUtils';
import VehiclePriceList from './vehiclePriceList';

interface VehicleTableProps {
    vehicles: COMPANY_API.Vehicle[];
    refresh: () => any;
}

export const VehiclesTable: React.FC<VehicleTableProps> = ({ vehicles, refresh }) => {
    const user = getUser()
    const [vehicleObject, setVehicleObject] = useState<COMPANY_API.Vehicle | null>(null);
    const [showEditVehicleModal, setShowEditVehicleModal] = useState(false);
    const [showDeleteVehicleModal, setShowDeleteVehicleModal] = useState(false);
    const [targetID, setTargetID] = useState('');
    const [targetName, setTargetName] = useState('');
    const [sortedField, setSortedField] = useState<string | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [showPrice, setShowPrice] = useState<boolean>(false)

    const handleSort = (field: string) => {
        if (sortedField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(field);
            setSortDirection('asc');
        }
    };

    const sortedVehicles = vehicles.slice().sort((a, b) => {
        if (sortedField) {
            const aValue = a[sortedField];
            const bValue = b[sortedField];

            return aValue.localeCompare(bValue) * (sortDirection === 'asc' ? 1 : -1);
        }
        return 0;
    });

    const handleShowEditVehicleModal = (object: COMPANY_API.Vehicle) => {
        setShowEditVehicleModal(true);
        setVehicleObject(object);
    };

    const handleHideEditVehicleModal = () => {
        setShowEditVehicleModal(false);
        setTargetID('');
        setTargetName('');
        refresh();
    };

    const handleShowDeleteVehicleModal = (id: string, name: string) => {
        setShowDeleteVehicleModal(true);
        setTargetID(id);
        setTargetName(name);
    };

    const handleHideDeleteVehicleModal = () => {
        setShowDeleteVehicleModal(false);
        setTargetID('');
        setTargetName('');
        refresh();
    };
    
    const handleShowPriceList=(object:COMPANY_API.Vehicle)=>{
        setShowPrice(true)
        setVehicleObject(object)
    }

    const handleHidePriceList=()=>{
        setShowPrice(false)
    }

    const getSortArrows = (field: string) => {
        const isSortedField = sortedField === field;
        const isAsc = sortDirection === 'asc';

        const upArrow = isSortedField && isAsc ? <FaCaretUp /> : <FaCaretUp style={{ opacity: 0.3 }} />;
        const downArrow = isSortedField && !isAsc ? <FaCaretDown /> : <FaCaretDown style={{ opacity: 0.3 }} />;

        return (
            <div className="flex-center">
                <div className="mr-2">
                    {upArrow}
                </div>
                <div>
                    {downArrow}
                </div>
            </div>
        );
    };


    if (vehicles.length === 0) {
        return (
            <div className="empty-message-container">
                <p className="empty-message">{words.no_data_available}</p>
            </div>
        );
    }
    

    return (
        <>
            <Table striped bordered hover responsive className="custom-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('name')} className="sort-header">
                            <div className="flex-center">
                                {words.vehicle_name} {getSortArrows('name')}
                            </div>
                        </th>
                        <th onClick={() => handleSort('height')} className="sort-header">
                            <div className="flex-center">
                                {words.height} {getSortArrows('height')}
                            </div>
                        </th>
                        <th>{words.weight}</th>
                        <th>{words.driver_mobile_number}</th>
                        <th>{words.actions}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedVehicles.map((vehicle:COMPANY_API.FullVehicle) => (
                        <tr
                            key={vehicle.id}
                            className={vehicle.company?.is_active ? 'greenRow' : 'RedRow'}
                        >
                            <td className='customLink' onClick={()=>handleShowPriceList(vehicle)}>{vehicle.name}</td>
                            <td>{vehicle.height}</td>
                            <td>{vehicle.weight}</td>
                            <td style={{direction:'ltr'}} >{vehicle.driver_mobile_number}</td>
                            <td>
                                <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditVehicleModal(vehicle)}>
                                    <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                                </Button>
                                {
                                user?.role === 'manager' || user?.is_superuser ?
                                    <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteVehicleModal(`${vehicle.id}`, vehicle.name)}>
                                        <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                                    </Button>
                                    :
                                    null
                                }

                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>

            <CustomModal show={showEditVehicleModal} onHide={handleHideEditVehicleModal} title={`${words.edit_vehicle_info}: ${vehicleObject?.name}`} >
                <EditVehicleForm onHide={handleHideEditVehicleModal} vehicle={vehicleObject!} />
            </CustomModal>

            <CustomModal show={showPrice} onHide={handleHidePriceList} title={`${words.price_table_list}: ${vehicleObject?.name}`} >
                <VehiclePriceList vehicle={vehicleObject!}/>
            </CustomModal>

            <CustomModal show={showDeleteVehicleModal} onHide={handleHideDeleteVehicleModal} title={`${words.delete_action} ${words.vehicle}: ${targetName}`} >
                <DeleteVehicleForm onHide={handleHideDeleteVehicleModal} id={targetID} name={targetName} />
            </CustomModal>
        </>
    );
};
