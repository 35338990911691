import axiosInstance from '../../helper/axiosInstance'

import { LOGISTICS_ENDPOINT,LOGISTICS_COMPANY_VEHICLE_ENDPOINT, VEHICLE_ENDPOINT } from '../../constants/endpoints'

export const GetAllLogisticsCompanies = async () => {
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+'logistics-companies/');
    return response;
};


export const GetAllVehiclesForCompany = async (id:string) => {
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+'logistics-companies/'+id+LOGISTICS_COMPANY_VEHICLE_ENDPOINT);
    return response;
};

export const GetAllVehicles= async () => {
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+VEHICLE_ENDPOINT);
    return response;
};


export const price_entries= async (id:string) => {
    console.log(id)
    let response: any;
    response = await axiosInstance.get(LOGISTICS_ENDPOINT+'price_entry/'+id+'/');
    return response;
};