import React, { useState } from 'react';
import { cites } from '../constants/cites';
import { COMPANY_API } from '../models/company';
import { useQuery, useMutation } from 'react-query';
import { price_entries } from '../redux/services/logisticsServices';
import { Loading } from './loading';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';
import { toast } from 'react-toastify';
import apiInstance from '../helper/axiosInstance';
import { Form, InputGroup, Pagination } from 'react-bootstrap';
import { words } from '../constants/translate';

type props = {
  vehicle: COMPANY_API.Vehicle
}
type price = {
  id: string,
  from_location: string,
  to_location: string,
  price: string,
}

const inputStyles = {
  padding: '0.5rem',
  borderRadius: '4px',
  border: '1px solid #ccc',
  transition: 'opacity 0.3s ease',
  width: '100%',
  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
};


const VehiclePriceList = ({ vehicle }: props) => {
  const [editingId, setEditingId] = useState<string | null>(null);
  const [editingValue, setEditingValue] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const items = 80
  const { data: price, status, refetch } = useQuery('price_entries', () => price_entries(vehicle.id));

  const priceMutation = useMutation(
    (data) => apiInstance.post(LOGISTICS_ENDPOINT + 'transport_price_entry/', data),
    {
      onSuccess: () => {
        toast.success(words.done);
        refetch();
      },
      onError: (error: any) => {
        toast.error(words.something_wrong);
      }
    }
  );

  if (status === 'loading') {
    return <Loading />
  }



  const processedData = [...price.data];
  const existingToLocations = new Set(price.data.map((record: price) => record.to_location));

  let maxId = Math.max(...price.data.map((record: price) => record.id));

  cites.forEach(city => {
    if (!existingToLocations.has(city)) {
      maxId += 1;
      processedData.push({ id: maxId, from_location: "ג'לג'וליה	", to_location: city, price: '' });
    }
  });

  const filteredData = processedData.filter(record =>
    record.from_location.toLowerCase().includes(searchTerm.toLowerCase()) ||
    record.to_location.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const paginatedData = filteredData.slice((currentPage - 1) * items, currentPage * items);


  const handleEdit = (recordId: string, price: string) => {
    setEditingId(recordId);
    setEditingValue(price);
  };

  const HandleAPI = (payload: any) => {
    priceMutation.mutate(payload);
  }

  const handleFinishEditing = (recordId: string) => {
    if (editingId === recordId) {
      const currentRecord = processedData.find(record => record.id === recordId);

      const payload = {
        vehicle_id: vehicle.id,
        from_location: currentRecord?.from_location,
        to_location: currentRecord?.to_location,
        price: editingValue,
      };

      if (editingValue) {
        HandleAPI(payload)
      }


      setEditingId(null);
      setEditingValue('');
    }

  };
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };
  return (
    <div className="container mt-4">
      <Form className="my-2">
        <Form.Group controlId="search">
          <InputGroup>
            <Form.Control
              className='customSearch p-3'
              type="text"
              placeholder={words.search}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Form.Group>
      </Form>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>{words.from_location}</th>
            <th>{words.to_location}</th>
            <th>{words.price}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((record) => (
            <tr key={`${record.id}-${record.from_location}-${record.to_location}`} onClick={() => handleEdit(record.id, record.price)}>
              <td>{record.from_location}</td>
              <td>{record.to_location}</td>
              <td>
                {
                  editingId === record.id
                    ? (
                      <input
                        style={inputStyles}
                        type="text"
                        value={editingValue}
                        onChange={e => setEditingValue(e.target.value)}
                        onBlur={() => handleFinishEditing(record.id)}
                        onKeyPress={e => e.key === 'Enter' && handleFinishEditing(record.id)}
                      />
                    )
                    : (
                      <span>
                        {record.price}
                      </span>
                    )
                }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex justify-content-center">
        <Pagination>
          {Array.from({ length: Math.ceil(processedData.length / items) }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => setCurrentPage(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>
  );
};

export default VehiclePriceList;