import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { ITEM_ENDPOINT, STORE_ENDPOINT } from '../constants/endpoints';
import { ORDER_API } from '../models/order';

interface EditItemFormProps {
  onHide: () => void;
  item: ORDER_API.item;
}

export const EditItemForm: React.FC<EditItemFormProps> = ({ onHide, item }) => {
  const [error, setError] = useState('');

  const [itemName, setItemName] = useState(item.name);

  const editItemMutation = useMutation<void, unknown, any>(
    (data) => {
      return apiInstance.patch(STORE_ENDPOINT + ITEM_ENDPOINT + `${item.id}/`, data).then(() => {
        onHide();
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (itemName.length < 2) {
      setError(words.fields_required);
      return;
    }

    const data = {
      name: itemName
    };

    editItemMutation.mutate(data);

  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}


      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={words.password}
            type="text"
            value={itemName}
            onChange={(event) => setItemName(event.target.value)}
            error={itemName.length === 0 ? words.fields_required : ''}
          />
        </div>
      </div>

      <Button variant="warning" className="mt-4" type="submit">
        {words.save_changes}
      </Button>
    </Form>
  );
};
