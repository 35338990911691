import React from 'react';
import '../assets/CSS/user.css';
import { useQuery } from 'react-query';
import { GetAllUsersService } from '../redux/services/userManagmentService';
import { Loading } from '../components/loading';
import { UserTable } from '../components/userTable';
import { FaCaretUp, FaCaretDown, FaPlusCircle } from 'react-icons/fa';

import { Container, Button, Alert } from 'react-bootstrap';
import { CustomModal } from '../components/customModal'

import { AddUserForm } from '../components/addNewUserForm'

import { words } from '../constants/translate'
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export default function Users() {
  const navigate = useNavigate()

  const [showAddNewUserModal, setShowAddNewUserModal] = React.useState(false);

  const [showTable, setShowTable] = React.useState(true);
  const { data, status, refetch } = useQuery('data', GetAllUsersService);

  const handleCloseNewUserModal = () => {
    setShowAddNewUserModal(false);
    refetch()
  };

  const handleShowNewUserModal = () => {
    setShowAddNewUserModal(true);
  };

  if (status === 'loading') {
    return <Loading />;
  }
  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <div className='d-flex justify-content-start mt-2'>
        <Button onClick={handleGoBack} className="back-button responsive-button">
          {words.back} <FiArrowRight className="back-icon" />
        </Button>
      </div>

      <Container fluid className='my-4 actionBar' >
        <Button variant='success' onClick={handleShowNewUserModal}>
          <FaPlusCircle /> {words.add_new_user}
        </Button>
      </Container>

      <div className="userTableContainer my-4">
        <div className="d-flex justify-content-between title-container">
          <h3 className="title">{words.user_list}</h3>
          <div className="icon-container" style={{ zIndex: 2 }} onClick={() => setShowTable(!showTable)}>
            {showTable ? (
              <FaCaretUp size={20} className="icon-up mx-4" />
            ) : (
              <FaCaretDown size={20} className="icon-down mx-4" />
            )}
          </div>
        </div>
        <div className={`TableWrap ${showTable ? 'showTable' : 'hideTable'}`}>
          {status === 'error' ?
            (
              <Alert variant="danger">{words.something_wrong}</Alert>
            ) : (
              <UserTable users={data.data} refresh={refetch} />

            )}

        </div>

      </div>

      <CustomModal show={showAddNewUserModal} onHide={handleCloseNewUserModal} title={words.add_new_user}>
        <AddUserForm refresh={refetch} />
      </CustomModal>

    </>
  );
}
