import axiosInstance from '../../helper/axiosInstance'

import { 
    LOGIN_ENDPOINT,
    LOGOUT_ENDPOINT,
    CHANGE_PASSWORD_ENDPOINT } from '../../constants/endpoints'

import { USER_API} from '../../models/user'

export const loginService = async (data: USER_API.Login) => {
    let response: any;
    response = await axiosInstance.post(LOGIN_ENDPOINT, data);
    return response;
};


export const logoutService = async () => {
    let response: any;
    response = await axiosInstance.post(LOGOUT_ENDPOINT);
    return response;
};


export const ChangePasswordService = async (data: USER_API.changePassword) => {
    let response: any;
    response = await axiosInstance.post(CHANGE_PASSWORD_ENDPOINT,data);
    return response;
};