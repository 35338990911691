import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/customModal';
import { getUser } from '../helper/authUtils';
import { GetAllSupplier } from '../redux/services/supplierService';
import { SupplierTable } from '../components/supplierTable';
import AddNewSupplierForm from '../components/addNewSupplierForm';


export default function Suppliers() {
  const user = getUser()

  const [showNewRequest, setShowNewRequest] = useState(false);

  const { data:suppliers_data, status:suppliers_status, refetch:suppliers_refetch } = useQuery('suppliers', GetAllSupplier);
  const navigate = useNavigate();
  
  const handleCloseNewRequestModal = () => {
    setShowNewRequest(false);
    suppliers_refetch();
  };

  const handleShowNewRequestModal = () => {
    setShowNewRequest(true);
  };

  if (suppliers_status === 'error' || suppliers_status === 'idle'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }

  if (suppliers_status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };


  return (
    <>
        <div className='d-flex justify-content-start mt-2'>
          <Button onClick={handleGoBack}  className="back-button responsive-button">
            {words.back} <FiArrowRight className="back-icon" />
          </Button>
        </div>
      

      <Container fluid className="my-4 actionBar">
        <Button variant="success" size="lg" onClick={handleShowNewRequestModal}>
          <FaPlusCircle /> {words.add_new_supplier}
        </Button>
      </Container>

      <div className="supplierTableContainer my-4">
        <div className="title-container">
          <h3 className="title">{words.supplier_page}</h3>
        </div>
        <div className={`TableWrap`}>          
          {suppliers_data.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<SupplierTable suppliers={suppliers_data.data} refresh={suppliers_refetch} />}
        </div>
      </div>

      <CustomModal show={showNewRequest} onHide={handleCloseNewRequestModal} title={words.add_new_supplier}>
          <AddNewSupplierForm refresh={suppliers_refetch}/>
      </CustomModal>
    </>
  );
}