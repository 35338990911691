import React, {useState } from 'react';
import { Table, Form, InputGroup, Pagination, Button } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa';
import { ORDER_API } from '../models/order';
import { words } from '../constants/translate';
import CustomModal from './customModal';
import { DeleteItemForm } from './deleteItemForm';
import { EditItemForm } from './editItemForm';


interface ItemsTableProps {
    items: ORDER_API.item[];
    refresh: () => any;
}

const ItemsTable: React.FC<ItemsTableProps> = ({ items,refresh }) => {
    const [selectedObject, setSelectedObject] = useState<ORDER_API.item | null >(null)
    const [showItemEditModal, setShowItemEditModal] = useState<boolean>(false)
    const [showItemDeleteModal, setShowItemDeleteModal] = useState<boolean>(false)

    const [searchTerm, setSearchTerm] = useState<string>("");
    const [currentPage, setCurrentPage] = useState<number>(1);
    const itemsPerPage = 10;

    const handleShowEditItemModal=(obj:ORDER_API.item)=>{
        setSelectedObject(obj)
        setShowItemEditModal(true)
    }
    const handleHideEditItemModal=()=>{
        setShowItemEditModal(false)
        refresh()
    }

    const handleShowDeleteItemModal=(obj:ORDER_API.item)=>{
        setShowItemDeleteModal(true)
        setSelectedObject(obj)
    }
    const handleHideDeleteItemModal=()=>{
        setShowItemDeleteModal(false)
        refresh()
    }
    const filteredItems = items.filter(item => 
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
    )
    
    const currentItems = filteredItems.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    )

    const totalPages = Math.ceil(filteredItems.length / itemsPerPage)
    return (
        <>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>
            <Table striped bordered hover responsive className="custom-table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentItems.map((item:ORDER_API.item) => (
                        <tr key={item.id}>
                            <td>{item.name}</td>
                            <td style={{width:'40%'}}>
                                <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditItemModal(item)}>
                                    <FaEdit /> <span>{words.edit_action}</span>
                                </Button>

                                <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteItemModal(item)}>
                                    <FaTrash /> <span>{words.delete_action}</span>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination>
                    {[...Array(totalPages).keys()].map(page => (
                        <Pagination.Item 
                            key={page+1} 
                            active={page+1 === currentPage}
                            onClick={() => setCurrentPage(page+1)}
                        >
                            {page+1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>
        
            <CustomModal show={showItemEditModal} onHide={handleHideEditItemModal} title='Edit Item'>
                <EditItemForm onHide={handleHideEditItemModal} item={selectedObject!}/>
            </CustomModal>

            <CustomModal show={showItemDeleteModal} onHide={handleHideDeleteItemModal} title='Delete Item'>
                <DeleteItemForm id={selectedObject?.id!} item_name={selectedObject?.name!} onHide={handleHideDeleteItemModal}/>
            </CustomModal>

        </>
    );
};

export default ItemsTable;
