import React, { useMemo, useState } from 'react';
import { Table, Form, InputGroup, Pagination, Button } from 'react-bootstrap';
import { FaEye, FaTimesCircle, FaTrash } from 'react-icons/fa';
import { words } from '../constants/translate';
import { REQUEST_API } from '../models/request';
import CustomModal from './customModal';
import { CancelRequestForm } from './cancleRequestForm';
import { DeleteRequestForm } from './deleteReuqestFrom';
import { DateFormat, TimeFormat } from '../helper/time';
import { EditRequestForm } from './editRequestForm';

interface RequestTableProps {
    data: REQUEST_API.Data[];
    refresh: () => any;
}

const RequestTable: React.FC<RequestTableProps> = ({ data, refresh }) => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showViewModal, setShowViewModal] = useState(false);
   
    const [requestObject, setRequestObject] = useState<REQUEST_API.Data | null>(null);

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    const handleShowCancelModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowCancelModal(true)

    }
    const handleHideCancelModal = () => {
        setShowCancelModal(false)
        refresh()
    }

    const handleShowDeleteModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowDeleteModal(true)

    }
    const handleHideDeleteModal = () => {
        setShowDeleteModal(false)
        refresh()
    }

    const handleShowViewModal = (object: REQUEST_API.Data) => {
        setRequestObject(object)
        setShowViewModal(true)

    }
    const handleHideViewModal = () => {
        setShowViewModal(false)
        refresh()
    }

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredRequest = [...data].sort((a: any, b: any) => b?.id - a?.id).filter((record: any) => {
        const searchValue = searchTerm.toLowerCase(); 
        return (
            record.request.created_by.username.toLowerCase().includes(searchValue) ||
            record.request.supplier.name.toLowerCase().includes(searchValue) ||
            record.request.create_date.toLowerCase().includes(searchValue) ||
            record.request.create_time.toLowerCase().includes(searchValue)
        );
    });

    const totalItems = filteredRequest.length;
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedRequests = filteredRequest.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </Form.Group>
            </Form>
            <Table striped bordered hover responsive className="custom-table">
                <thead>
                    <tr>
                        <th>{words.request_id}</th>
                        <th>{words.request_send_by}</th>
                        <th>{words.supplier_name}</th>
                        <th>{words.delivery_date}</th>
                        <th>{words.delivery_time}</th>
                        <th>{words.request_send_date}</th>
                        <th>{words.request_send_time}</th>
                        <th>{words.actions}</th>
                    </tr>
                </thead>
                <tbody>
                    {paginatedRequests.map((record:any) => (
                        <tr key={record.request.id} className={record.request.done ? 'greenRow' : record.request.canceled ? 'redRow' : 'orangeRow'}>
                            <td>{record.request.id}</td>
                            <td>{record.request.created_by.username}</td>
                            <td>{record.request.supplier.name}</td>
                            <td>{DateFormat(record.request.delivery_date)}</td>
                            <td>{TimeFormat(record.request.delivery_start_time)}</td>
                            <td>{DateFormat(record.request.create_date)}</td>
                            <td>{TimeFormat(record.request.create_time)}</td>
                            <td>
                                <Button className='responsive-button m-1' size='sm' onClick={() => handleShowViewModal(record)}>
                                    <FaEye className="button-icon" />
                                </Button>
                                {
                                    record.request.canceled ?
                                        null
                                        :
                                        <Button variant="warning" className='responsive-button m-1' size='sm' onClick={() => handleShowCancelModal(record)}>
                                            <FaTimesCircle className="button-icon" /> <span>{words.cancel_message_alies}</span>
                                        </Button>
                                }
                                <Button variant="danger" className='responsive-button m-1' size='sm' onClick={() => handleShowDeleteModal(record)}>
                                    <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination>
                    {Array.from({ length: Math.ceil(totalItems / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>
            <div className="agenda-boxes">

                <div className="agenda-box">
                    <div className="box greenRow"></div>
                    <div className="box-title">{words.done}</div>
                </div>

                <div className="agenda-box">
                    <div className="box redRow"></div>
                    <div className="box-title">{words.canceled_request}</div>
                </div>

                <div className="agenda-box">
                    <div className="box orangeRow"></div>
                    <div className="box-title">{words.not_done}</div>
                </div>

            </div>
            <CustomModal show={showViewModal} onHide={handleHideViewModal} title={`${requestObject?.request.id}`} >
                <EditRequestForm data={requestObject!} onHide={handleHideViewModal} refresh={refresh}/>
            </CustomModal>

            <CustomModal show={showCancelModal} onHide={handleHideCancelModal} title={`${words.cancel_message_alies}: ${requestObject?.request.id}`} >
                <CancelRequestForm request={requestObject?.request!} onHide={handleHideCancelModal} />
            </CustomModal>

            <CustomModal show={showDeleteModal} onHide={handleHideDeleteModal} title={`${words.delete_message} ${words.request}: ${requestObject?.request.id}`} >
                <DeleteRequestForm request={requestObject?.request!} onHide={handleHideDeleteModal} />
            </CustomModal>

        </>
    );
};

export default RequestTable;
