import { ReactElement, useState } from 'react';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';
import {LogoutAction} from '../../redux/actions/userAction';
import {useAppDispatch} from '../../redux/store';
import { clearError } from '../../redux/reducers/index';
import {words} from '../../constants/translate'
import {PasswordChangeForm} from '../chnagePasswordForm'
import {CircleCharacter} from '../circleCharacter'
import {CustomModal} from '../customModal'


export function TopBar() {

  const dispatch = useAppDispatch();
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const handleClosePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleShowPasswordModal = () => {
    dispatch(clearError());
    setShowPasswordModal(true);
  };

  const handleLogout = () => {
    dispatch(LogoutAction());
  };

  return (
    <>
    <Navbar collapseOnSelect className="bg-body-tertiary" style={{ boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)' }}>
      <Container dir="rtl" fluid>
        <Navbar.Brand href="/" className="fs-3">
          {words.business_name}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto"></Nav>
          <Nav>
            <NavDropdown title={<CircleCharacter/>} id="collasible-nav-dropdown" className="fs-5 circle-dropdown">
              <NavDropdown.Item onClick={handleShowPasswordModal}  style={{ textAlign: 'right' }}>
                {words.change_password}
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={()=>{handleLogout()}}  style={{ textAlign: 'right' }}>
                {words.logout}
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>

    </Navbar>

    <CustomModal show={showPasswordModal} onHide={handleClosePasswordModal} title={words.change_password}>
      <PasswordChangeForm onHide={handleClosePasswordModal} />
    </CustomModal>

    </>
  );
}

