import React, { useState } from 'react';
import { Table, Form, InputGroup, Pagination, Col, Row } from 'react-bootstrap';
import { words } from '../constants/translate';
import { ORDER_API } from '../models/order'
import '../assets/CSS/report.css'
import { DateFormat, TimeFormat } from '../helper/time';


interface OrdersListTableProps {
    orders: ORDER_API.Data[];
}

const OrderReport: React.FC<OrdersListTableProps> = ({ orders }) => {
    const getStartOfMonth = ():string => {
        const date = new Date();
        date.setDate(1);
        return date.toISOString().slice(0, 10);
    };

    const getEndOfMonth = () :string => {
        const date = new Date();
        date.setMonth(date.getMonth() + 1);
        date.setDate(0);
        return date.toISOString().slice(0, 10);
    };

    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;
    const [startDate, setStartDate] = useState<string>(getStartOfMonth());
    const [endDate, setEndDate] = useState<string>(getEndOfMonth());
    const [showNotDoneOnly, setShowNotDoneOnly] = useState(false);



    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const filteredOrders = [...orders].sort((a: any, b: any) => b?.id - a?.id).filter((data: any) => {
        const searchValue = searchTerm.toLowerCase();
        return (
            data.order.customer_name.toLowerCase().includes(searchValue) ||
            data.order.delivery_place.toLowerCase().includes(searchValue) ||
            data.order.delivery_time.includes(searchValue) ||
            data.order.delivery_date.includes(searchValue) ||
            data.order.vehicle_id?.name.includes(searchValue) || 
            data.order.vehicle_id?.company.name.includes(searchValue)
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const paginatedOrders = filteredOrders.slice(indexOfFirstItem, indexOfLastItem);



    const dateFilteredOrders = filteredOrders.filter(order => {
        const orderDate = new Date(order.order.delivery_date);
        const start = new Date(startDate);
        const end = new Date(endDate);
        const dateCondition = (!startDate || orderDate >= start) && (!endDate || orderDate <= end);
        if (showNotDoneOnly) {
            return dateCondition && !order.order.done;
        }
        return dateCondition;
    });

    const totalOrders = dateFilteredOrders.length;
    const doneOrders = dateFilteredOrders.filter(order => order.order.done).length;
    const notDoneOrders = totalOrders - doneOrders;
    const totalDeliveryPrice = dateFilteredOrders.reduce((acc, order) => acc + Number(order.order.delivery_price), 0);

    return (
        <>
            <div className="d-flex justify-content-around my-4">
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.total_orders}</h5>
                        <p className="report-card-text">{totalOrders}</p>
                    </div>
                </div>
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.orders_done}</h5>
                        <p className="report-card-text">{doneOrders}</p>
                    </div>
                </div>
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.orders_not_done}</h5>
                        <p className="report-card-text">{notDoneOrders}</p>
                    </div>
                </div>
                <div className="report-card text-center">
                    <div className="report-card-body">
                        <h5 className="report-card-title">{words.price_sun}</h5>
                        <p className="report-card-text">₪{totalDeliveryPrice}</p>
                    </div>
                </div>
            </div>
            <Form className="my-2">
                <Form.Group controlId="search">
                    <InputGroup>
                        <Form.Control
                            className='customSearch p-3'
                            type="text"
                            placeholder={words.search}
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                    </InputGroup>
                </Form.Group>


                <Row className='m-3'>
                    <Form.Group as={Col} md={2} controlId="showNotDoneOnly" className="d-flex align-items-center">
                        <Form.Check
                            type="checkbox"
                            label={words.show_not_done_orders}
                            checked={showNotDoneOnly}
                            onChange={(e) => setShowNotDoneOnly(e.target.checked)}
                        />
                    </Form.Group>

                    <Form.Group as={Col} md={3} controlId="startDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.start_date}</Form.Label>
                        <Form.Control type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
                    </Form.Group>

                    <Form.Group as={Col} md={3} controlId="endDate" className="d-flex align-items-center">
                        <Form.Label className="date-label">{words.end_date}</Form.Label>
                        <Form.Control type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
                    </Form.Group>

                </Row>

            </Form>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>{words.order_id}</th>
                        <th>{words.customer_name}</th>
                        <th>{words.customer_mobile_number}</th>
                        <th>{words.delivery_place}</th>
                        <th>{words.delivery_time}</th>
                        <th>{words.delivery_date}</th>
                        <th>{words.delivery_height}</th>
                        <th>{words.price}</th>
                        <th>{words.vehicle}</th>
                        <th>{words.done}</th>
                    </tr>
                </thead>
                <tbody>
                    {dateFilteredOrders.map((data: ORDER_API.Data) => (
                        <tr key={data.order.id} className={data.order.vehicle_id ? data.order.done ? 'greenRow' : 'redRow' : 'orangeRow'}>
                            <td>{data.order.id}</td>
                            <td>{data.order.customer_name}</td>
                            <td>{data.order.customer_phone}</td>
                            <td>{data.order.delivery_place}</td>
                            <td>{TimeFormat(data.order.delivery_start_time)}</td>
                            <td>{DateFormat(data.order.delivery_date)}</td>
                            <td>{data.order.delivery_height}</td>
                            <td>{data.order.delivery_price}</td>
                            <td>{data.order.vehicle_id?.name + ' || ' + data.order.vehicle_id?.company.name}</td>
                            <td>{data.order.done ? words.yes : words.no}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="d-flex justify-content-center">
                <Pagination>
                    {Array.from({ length: Math.ceil(paginatedOrders.length / itemsPerPage) }).map((_, index) => (
                        <Pagination.Item
                            key={index}
                            active={index + 1 === currentPage}
                            onClick={() => setCurrentPage(index + 1)}
                        >
                            {index + 1}
                        </Pagination.Item>
                    ))}
                </Pagination>
            </div>

            <div className="agenda-boxes">
                <div className="agenda-box">
                    <div className="box redRow"></div>
                    <div className="box-title">{words.not_done}</div>
                </div>
                <div className="agenda-box">
                    <div className="box greenRow"></div>
                    <div className="box-title">{words.done}</div>
                </div>
                <div className="agenda-box">
                    <div className="box orangeRow"></div>
                    <div className="box-title">{words.not_selected}</div>
                </div>
            </div>
        </>

    );
};

export default OrderReport;
