import axiosInstance from '../../helper/axiosInstance'

import { STORE_ENDPOINT,ORDER_ENDPOINT, ITEM_ENDPOINT } from '../../constants/endpoints'



export const GetAllOrdersList = async () => {
    let response: any;
    response = await axiosInstance.get(STORE_ENDPOINT+ORDER_ENDPOINT);
    return response;
};

export const GetAllItems = async () => {
    let response: any;
    response = await axiosInstance.get(STORE_ENDPOINT+ITEM_ENDPOINT);
    return response;
};