import React, { useState } from 'react';
import { Table, Form, InputGroup, Pagination, Button } from 'react-bootstrap';
import '../assets/CSS/priceTable.css'
import { words } from '../constants/translate';
import { FaEdit, FaTrash } from 'react-icons/fa';
import CustomModal from './customModal';
import { PRICE_API } from '../models/price';
import { DeletePriceForm } from './deletePriceForm';
import { EditPriceForm } from './editPriceFrom';

interface PricesListTableProps {
  prices: PRICE_API.Price[];
  refresh:()=>any
}


const PricesListTable: React.FC<PricesListTableProps> = ({ prices,refresh }) => {
  const [showEditPriceModal, setShowEditPriceModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [priceObject, setPriceObject] = useState<PRICE_API.Price | null>(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleShowEditPriceModal = (object:PRICE_API.Price) => {
    setShowEditPriceModal(true);
    setPriceObject(object)
  };
  
  const handleHideEditPriceModal = () => {
    setShowEditPriceModal(false);
    refresh()
  };
  
  const handleShowDeletePriceModal = (object:PRICE_API.Price) => {
    setShowDeleteModal(true);
    setPriceObject(object)
  };
  
  const handleHideDeletePriceModal = () => {
    setShowDeleteModal(false);
    refresh()
  };

  
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredPrices = prices.filter(price => {
    const searchValue = searchTerm.toLowerCase();
    return (
      price.from_location.toLowerCase().includes(searchValue) ||
      price.to_location.toLowerCase().includes(searchValue) ||
      price.price.toString().includes(searchValue) ||
      price.vehicle.name.toLowerCase().includes(searchValue)
    );
  });

  const handleSort = (columnName: keyof PRICE_API.Price) => {
    if (sortColumn === columnName) {
      setSortDirection(prevSortDirection =>
        prevSortDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const sortedPrices = filteredPrices.sort((a, b) => {
    const isAsc = sortDirection === 'asc';
    let aValue: number | string;
    let bValue: number | string;

    if (sortColumn === 'from_location' || sortColumn === 'to_location') {
      aValue = a[sortColumn];
      bValue = b[sortColumn];
    } else if (sortColumn === 'vehicle.name') {
      aValue = a.vehicle.name;
      bValue = b.vehicle.name;
    } else if (sortColumn === 'price') {
      aValue = a.price;
      bValue = b.price;
    } else {
      aValue = '';
      bValue = '';
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      aValue = aValue.toLowerCase();
      bValue = bValue.toLowerCase();
    }

    if (aValue < bValue) {
      return isAsc ? -1 : 1;
    }
    if (aValue > bValue) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(sortedPrices.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedPrices = sortedPrices.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
    <div className="table-responsive">
      <Form className="my-2">
        <Form.Group controlId="search">
          <InputGroup>
            <Form.Control
              className='customSearch p-3'
              type="text"
              placeholder={words.search}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Form.Group>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>{words.company_name}</th>
            <th>{words.driver_mobile_number}</th>
            <th onClick={() => handleSort('from_location')}>{words.from_location}</th>
            <th onClick={() => handleSort('to_location')}>{words.to_location}</th>
            <th onClick={() => handleSort('vehicle')}>{words.vehicle_name}</th>
            <th>{words.height}</th>
            <th>{words.weight}</th>
            <th onClick={() => handleSort('price')}>{words.price}</th>
            <th>{words.actions}</th>
            
          </tr>
        </thead>
        <tbody>
          {paginatedPrices.map((price:PRICE_API.Price) => (
            <tr key={price.id}>
              <td>{price.vehicle.company}</td>
              <td>{price.vehicle.driver_mobile_number}</td>
              <td>{price.from_location}</td>
              <td>{price.to_location}</td>
              <td>{price.vehicle.name}</td>
              <td>{price.vehicle.height}</td>
              <td>{price.vehicle.weight}</td>
              <td>₪ {price.price}</td>
              <td>
                <Button variant="primary" className='responsive-button m-1' onClick={()=>handleShowEditPriceModal(price)}>
                  <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                </Button>

                <Button variant="danger" className='responsive-button m-1' onClick={()=>handleShowDeletePriceModal(price)}>
                  <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>


      <div className="d-flex justify-content-center">
        <Pagination>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
    </div>

      <CustomModal show={showEditPriceModal} onHide={handleHideEditPriceModal} title={words.edit_action + " " +priceObject?.from_location + " " + words.to+ " " + priceObject?.to_location+ " " + words.for+ " " + priceObject?.vehicle.name }>
        <EditPriceForm onHide={handleHideEditPriceModal} price={priceObject!}/>
      </CustomModal>

      <CustomModal show={showDeleteModal} onHide={handleHideDeletePriceModal} title={words.delete_action + " " +priceObject?.from_location + " " + words.to+ " " + priceObject?.to_location+ " " + words.for+ " " + priceObject?.vehicle.name }>
        <DeletePriceForm onHide={handleHideDeletePriceModal} price={priceObject!} />
      </CustomModal>
    </>
  );
};

export default PricesListTable;

