import React, { ReactNode, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { TopBar } from './nav'
import '../../assets/CSS/layout.css'
import { ToastContainer } from 'react-toastify';

export interface IMainLayoutProps {
    children: ReactNode;
}

export function MainLayout(props: IMainLayoutProps) {
    return (
        <Container fluid>
            <ToastContainer position="bottom-left" style={{ zIndex: 9999 }} />
            <Row>
                <Col> 
                    <TopBar/> 
                </Col>
            </Row>
            <Row className='mt-2'>
                <Col>
                    {props.children}
                </Col>
            </Row>
            {/* <Row >
                <Col>
                    <Footer/>
                </Col>
            </Row> */}
        </Container>
    );
}