import React from 'react';
import { Spinner } from 'react-bootstrap';
import '../assets/CSS/loading.css'; 
import { words } from '../constants/translate';

export const Loading: React.FC = () => {
  return (
    <div className="loading-page">
      <Spinner animation="border" role="status">
        <span className="visually-hidden">{words.loading}...</span>
      </Spinner>
      <h2>{words.loading}</h2>
    </div>
  );
};

