import React from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { USER_API } from '../models/user';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

interface AddUserFormProps {
  refresh: () => void;
}
const validationSchema = Yup.object().shape({
  username: Yup.string().required(words.fields_required),
  email: Yup.string().email('אימייל לא תקני').required(words.fields_required),
  firstName: Yup.string().required(words.fields_required),
  lastName: Yup.string().required(words.fields_required),
  password: Yup.string().required(words.fields_required),
  confirmPassword: Yup.string()
  .oneOf([Yup.ref('password')], 'הסיסמאות אינן תואמות')
  .required(words.fields_required),
  role: Yup.string().required('תפקיד לא תקני'),
  mobileNumber: Yup.string().required(words.fields_required).min(8,words.mobile_number_invalid),
});

export const AddUserForm: React.FC<AddUserFormProps> = ({ refresh }) => {
  const [error, setError] = React.useState('');



  const formik = useFormik({
    initialValues: {
      username: '',
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      confirmPassword: '',
      role: '',
      mobileNumber: '',
    },
    validationSchema,
    onSubmit: (values) => {
      const data: USER_API.newUser = {
        username: values.username,
        email: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        password: values.password,
        role: values.role,
        phone_number: values.mobileNumber,
      };

      createUserMutation.mutate(data);

      formik.resetForm();
    },
  });

  const createUserMutation = useMutation(
    (data: USER_API.newUser) => {
      return apiInstance.post('users/', data).then(() => {
        refresh();
        toast.success('הפעולה הושלמה בהצלחה');
      });
    },
    {
      onError: (error) => {
        setError(words.something_wrong);
      },
    }
  );

  return (
      <div className="container mt-4">
          <Form onSubmit={formik.handleSubmit}>
              {error && <Alert variant="danger">{error}</Alert>}
              
              <div className="row">
                  <div className="col-md-6">
                      <CustomInput
                          label={words.username}
                          type="text"
                          name="username"
                          value={formik.values.username}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={words.username_placeholder}
                          error={formik.touched.username && formik.errors.username}
                          autoComplete='off'
                      />
                  </div>
                  <div className="col-md-6">
                      <CustomInput
                          label={words.email}
                          type="email"
                          name="email"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={words.email_placeholder}
                          error={formik.touched.email && formik.errors.email}
                          autoComplete='off'
                      />
                  </div>
              </div>
  
              <div className="row">
                  <div className="col-md-6">
                      <CustomInput
                          label={words.first_name}
                          type="text"
                          name="firstName"
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={'זוהרי'}
                          error={formik.touched.firstName && formik.errors.firstName}
                          autoComplete='off'
                      />
                  </div>
                  <div className="col-md-6">
                      <CustomInput
                          label={words.last_name}
                          type="text"
                          name="lastName"
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={"גיוסי"}
                          error={formik.touched.lastName && formik.errors.lastName}
                          autoComplete='off'
                      />
                  </div>
              </div>
  
              <div className="row">
                  <div className="col-md-6">
                      <CustomInput
                          label={words.password}
                          isPassword
                          name="password"
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={words.password_placeholder}
                          error={formik.touched.password && formik.errors.password}
                          autoComplete='off'
                      />
                  </div>
                  <div className="col-md-6">
                      <CustomInput
                          label={words.s2_password}
                          isPassword
                          name="confirmPassword"
                          value={formik.values.confirmPassword}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={words.s2_password_placeholder}
                          error={formik.touched.confirmPassword && formik.errors.confirmPassword}
                          autoComplete='off'
                      />
                  </div>
              </div>
  
              <div className="row">
                  <div className="col-md-6">
                      <CustomInput
                          label={words.mobile_number}
                          type="text"
                          name="mobileNumber"
                          value={formik.values.mobileNumber}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder={words.mobile_number_placeholder}
                          error={formik.touched.mobileNumber && formik.errors.mobileNumber}
                          autoComplete='off'
                      />
                  </div>
                  <div className="col-md-6">
                      <Form.Group className="p-1">
                          <Form.Label>{words.role}</Form.Label>
                          <Form.Control 
                              as="select" 
                              name="role"
                              value={formik.values.role} 
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              isInvalid={formik.touched.role && !!formik.errors.role}
                          >
                              <option value="" disabled>
                                  {words.role_select}
                              </option>
                              <option value="manager">{words.manager}</option>
                              <option value="worker">{words.worker}</option>
                          </Form.Control>
                          {formik.touched.role && formik.errors.role && <Form.Control.Feedback type="invalid">{formik.errors.role}</Form.Control.Feedback>}
                      </Form.Group>
                  </div>
              </div>
  
              <Button variant="primary" className="mt-4" type="submit">
                  {words.add_new_user_btn}
              </Button>
          </Form>
      </div>
  );
};
