import * as React from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import '../assets/CSS/companyDetails.css';
import { Alert, Button } from 'react-bootstrap';
import { FiArrowRight } from 'react-icons/fi';
import { useQuery } from 'react-query';
import { GetAllVehiclesForCompany } from '../redux/services/logisticsServices';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { VehiclesTable } from '../components/vehiclesTable';
import { FaPlusCircle } from 'react-icons/fa';
import CustomModal from '../components/customModal';
import { AddVehicleForm } from '../components/addNewVehicleForm';

function LogisticsCompanyDetails() {
  const [addNewVehicleModalShow, setAddNewVehicleModalShow] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  if (!id) {
    navigate('/');
  }
  const { data:vehicles, status, refetch } = useQuery('vehicles', () => GetAllVehiclesForCompany(id!));

  if (status === 'error' || status === 'idle') {
    return <Alert variant="danger">{words.something_wrong}</Alert>;
  }

  if (status === 'loading') {
    return <Loading/>;
  }

  const handleGoBack = () => {
    navigate(-1);
  };

  const handelShowNewVehicleModa = () => {
    setAddNewVehicleModalShow(true);
  };
  const handelHideNewVehicleModa = () => {
    setAddNewVehicleModalShow(false);
    refetch();
  };

  return (
    <>
      <div>

        <div className='d-flex justify-content-start mt-2'>
          <Button onClick={handleGoBack} className="back-button responsive-button">
            {words.back} <FiArrowRight className="back-icon" />
          </Button>
        </div>

        <div className="d-flex justify-content-center my-4 company-details-header">
          {vehicles && vehicles.data && vehicles.data.length !== 0 ? (
            <h2 className="mr-auto ml-auto company-details-title">{`${words.company_details}: ${vehicles.data[0].company?.name}`}</h2>
          ) : null}
        </div>

        <div className="info-box">
          <h4>{words.total_vehicles}: {vehicles.data.length}</h4>
        </div>

        <div className="my-4 actionBar">
          <Button variant="success" className="responsive-button" onClick={handelShowNewVehicleModa}>
            <FaPlusCircle /> {words.add_new_vehicle}
          </Button>
        </div>

        <div className="userTableContainer my-4">
          <div className="title-container">
            <h3 className="title">{words.vehicles_list}</h3>
          </div>
          <div className={`TableWrap`}>
          {vehicles.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<VehiclesTable vehicles={vehicles.data} refresh={refetch} />}
          </div>
        </div>
      </div>

      <CustomModal onHide={handelHideNewVehicleModa} show={addNewVehicleModalShow} title={words.add_new_vehicle}>
        <AddVehicleForm refresh={refetch} company_id={id!} />
      </CustomModal>
    </>
  );
}

export default LogisticsCompanyDetails;
