import React from 'react';
import { useMutation } from 'react-query';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Button, Alert } from 'react-bootstrap';
import apiInstance from '../helper/axiosInstance';
import { toast } from 'react-toastify';
import { words } from '../constants/translate';
import { COMPANY_API } from '../models/company';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';

interface AddVehicleFormProps {
    refresh: () => void;
    company_id: string;
}

const VehicleSchema = Yup.object().shape({
    name: Yup.string().required(words.name_required),
    height: Yup.string().required(words.name_required).matches(/^(?:0|[1-9]\d*)(?:\.\d+)?$/, words.height_required),
    weight: Yup.string().required(words.name_required).matches(/^(?:0|[1-9]\d*)(?:\.\d+)?$/, words.weight_required),
    driver_mobile_number: Yup.string().min(8,words.mobile_number_invalid),
});

export const AddVehicleForm: React.FC<AddVehicleFormProps> = ({ refresh, company_id }) => {
    const addVehicleMutation = useMutation<void, unknown, COMPANY_API.NewVehicle>(
        (data) => {
            return apiInstance.post(`${LOGISTICS_ENDPOINT}vehicle/`, data).then(() => {
                refresh();
                toast.success('הפעולה הושלמה בהצלחה');
            });
        }
    );

    return (
        <Formik
            initialValues={{
                name: '',
                height: '',
                weight: '',
                driver_mobile_number: '',
            }}
            validationSchema={VehicleSchema}
            onSubmit={(values, { setStatus,resetForm }) => {
                const newVehicle: COMPANY_API.NewVehicle = {
                    ...values,
                    company: company_id,
                };
                addVehicleMutation.mutate(newVehicle, {
                    onError: () => {
                        setStatus(words.something_wrong);
                    },
                    onSuccess: () => {
                        resetForm(); 
                    }
                });

            }}
        >
            {({ errors, status }) => (
                <Form>
                    {status && <Alert variant="danger">{status}</Alert>}
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <label>{words.vehicle_name}</label>
                            <Field name="name" type="text" className="form-control" />
                            <ErrorMessage name="name" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-6 my-2">
                            <label>{words.height}</label>
                            <Field name="height" type="text" className="form-control" />
                            <ErrorMessage name="height" component="div" className="text-danger" />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 my-2">
                            <label>{words.weight}</label>
                            <Field name="weight" type="text" className="form-control" />
                            <ErrorMessage name="weight" component="div" className="text-danger" />
                        </div>
                        <div className="col-md-6 my-2">
                            <label>{words.driver_mobile_number}</label>
                            <Field name="driver_mobile_number" type="text" className="form-control" />
                            <ErrorMessage name="driver_mobile_number" component="div" className="text-danger" />
                        </div>
                    </div>
                    <Button variant="primary" className="mt-4" type="submit">
                        {words.add_new_vehicle}
                    </Button>
                </Form>
            )}
        </Formik>
    );
};
