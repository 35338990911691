import * as React from 'react';
import { useQuery } from 'react-query';
import { GetAllLogisticsCompanies } from '../redux/services/logisticsServices';
import { LogisticsCompaniesTable } from '../components/logisticsCompaniesTable';
import '../assets/CSS/logistics.css';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert } from 'react-bootstrap';

import { FaPlusCircle } from 'react-icons/fa';
import { getUser } from '../helper/authUtils';
import CustomModal from '../components/customModal';
import { AddCompanyForm } from '../components/addNewCompanyForm';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

export interface ILogisticsProps {}

export default function Logistics(props: ILogisticsProps) {
  const [showNewCompany, setShowNewCompany] = React.useState(false);
  const { data:companies, status, refetch } = useQuery('companies', GetAllLogisticsCompanies);
  const navigate = useNavigate()
  
  const handleCloseNewCompanyModal = () => {
    setShowNewCompany(false);
    refetch();
  };

  const handleShowNewCompanyModal = () => {
    setShowNewCompany(true);
  };

  if (status === 'loading') {
    return <Loading />;
  }
  
  if (status === 'error' || status==='idle'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }
  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <>
      <div className='d-flex justify-content-start mt-2'>
          <Button onClick={handleGoBack} className="back-button responsive-button">
              {words.back} <FiArrowRight className="back-icon" />
          </Button>
      </div>
     
        <Container fluid className="my-4 actionBar">
          <Button variant="success" size="lg" onClick={handleShowNewCompanyModal}>
            <FaPlusCircle /> {words.add_new_company}
          </Button>
        </Container>


      <div className="userTableContainer my-4">
        <div className="title-container">
          <h3 className="title">{words.logistics_companies}</h3>
        </div>
        <div className={`TableWrap`}>
          {companies.data.length === 0 ? null:<LogisticsCompaniesTable logisticsCompanies={companies.data} refresh={refetch} />}
        </div>
      </div>

      <CustomModal show={showNewCompany} onHide={handleCloseNewCompanyModal} title={words.add_new_company}>
        <AddCompanyForm refresh={refetch} />
      </CustomModal>
    </>
  );
}
