import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaCaretUp, FaCaretDown, FaEdit, FaTrash } from 'react-icons/fa';
import CustomModal from './customModal';
import { SUPPLIER_API } from '../models/supplier';
import { words } from '../constants/translate';
import { DeleteSupplierForm } from './deleteSupplierForm';
import EditSupplierForm from './editSupplierForm';

interface SupplierTableProps {
    suppliers: SUPPLIER_API.Supplier[];
    refresh: () => any;
}

export const SupplierTable: React.FC<SupplierTableProps> = ({ suppliers, refresh }) => {
    const [supplierObject, setSupplierObject] = useState<SUPPLIER_API.Supplier | null>(null);
    const [showEditSupplierModal, setShowEditSupplierModal] = useState(false);
    const [showDeleteSupplierModal, setShowDeleteSupplierModal] = useState(false);
    const [sortedField, setSortedField] = useState<keyof SUPPLIER_API.Supplier | null>(null);
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

    const handleSort = (field: string) => {
        if (sortedField === field) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortedField(field);
            setSortDirection('asc');
        }
    };

    const sortedSuppliers = suppliers.slice().sort((a, b) => {
        if (sortedField) {
            const aValue = a[sortedField];
            const bValue = b[sortedField];

            if (typeof aValue === 'string' && typeof bValue === 'string') {
                return aValue.localeCompare(bValue) * (sortDirection === 'asc' ? 1 : -1);
            }
        }
        return 0;
    });

    const handleShowEditSupplierModal = (supplier: SUPPLIER_API.Supplier) => {
        setShowEditSupplierModal(true);
        setSupplierObject(supplier);
    };

    const handleHideEditSupplierModal = () => {
        setShowEditSupplierModal(false);
        refresh();
    };

    const handleShowDeleteSupplierModal = (supplier: SUPPLIER_API.Supplier) => {
        setShowDeleteSupplierModal(true);
        setSupplierObject(supplier);
    };

    const handleHideDeleteSupplierModal = () => {
        setShowDeleteSupplierModal(false);
        refresh();
    };

    const getSortArrows = (field: string) => {
        const isSortedField = sortedField === field;
        const isAsc = sortDirection === 'asc';

        const upArrow = isSortedField && isAsc ? <FaCaretUp /> : <FaCaretUp style={{ opacity: 0.3 }} />;
        const downArrow = isSortedField && !isAsc ? <FaCaretDown /> : <FaCaretDown style={{ opacity: 0.3 }} />;

        return (
            <div className="flex-center">
                <div className="mr-2">
                    {upArrow}
                </div>
                <div>
                    {downArrow}
                </div>
            </div>
        );
    };

    if (suppliers.length === 0) {
        return (
            <div className="empty-message-container">
                <p className="empty-message">{words.no_data_available}</p>
            </div>
        );
    }

    return (
        <>
            <Table striped bordered hover responsive className="custom-table">
                <thead>
                    <tr>
                        <th onClick={() => handleSort('name')} className="sort-header">
                            <div className="flex-center">
                                {words.supplier_name} {getSortArrows('name')}
                            </div>
                        </th>
                        <th>{words.whatsapp_number}</th>
                        <th>{words.actions}</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedSuppliers.map((supplier:SUPPLIER_API.Supplier) => (
                        <tr
                            key={supplier.id}
                            className={supplier.is_active ? 'greenRow' : 'redRow'}
                        >
                            <td>{supplier.name}</td>
                            <td style={{direction:'ltr'}}>{supplier.whatsapp_number}</td>
                            <td>
                                <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditSupplierModal(supplier)}>
                                    <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                                </Button>
 
                                <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteSupplierModal(supplier)}>
                                    <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <CustomModal show={showEditSupplierModal} onHide={handleHideEditSupplierModal} title={`${words.edit_action}: ${supplierObject?.name}`} >
                <EditSupplierForm supplier={supplierObject!} onHide={handleHideEditSupplierModal}/>
            </CustomModal>

            <CustomModal show={showDeleteSupplierModal} onHide={handleHideDeleteSupplierModal} title={`${words.delete_action}: ${supplierObject?.name}`} >
                <DeleteSupplierForm supplier={supplierObject!} onHide={handleHideDeleteSupplierModal} />
            </CustomModal>
        </>
    );
};
