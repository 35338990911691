import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { USER_API } from '../models/user';
import { CHANGE_USERS_PASSWORD_ENDPOINT } from '../constants/endpoints';

interface EditPasswordFormProps {
  onHide: () => void;
  id: string;
}

export const EditPasswordForm: React.FC<EditPasswordFormProps> = ({ onHide, id }) => {
  const [error, setError] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const editPasswordMutation = useMutation<void, unknown, USER_API.change_user_password>(
    (data) => {
      return apiInstance.post(CHANGE_USERS_PASSWORD_ENDPOINT, data).then(() => {
        onHide();
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (password.length < 7) {
      setError(words.password_error);
      return;
    }

    if (password !== confirmPassword) {
      setError(words.password_dose_not_matched);
      return;
    }

    const data: USER_API.change_user_password = {
      user_id: id,
      new_password: password,
    };

    editPasswordMutation.mutate(data);

    setPassword('');
    setConfirmPassword('');
  };

  return (
    <Form onSubmit={handleSubmit}>
      {error && <Alert variant="danger">{error}</Alert>}

      <CustomInput
        label={words.password}
        type="password"
        value={password}
        onChange={(event) => setPassword(event.target.value)}
        error={password.length < 7 && password.length !== 0 ? words.password_error : ''}
        isPassword
        className={password.length < 7 && password.length !== 0 ? 'is-invalid' : ''}
      />

      <CustomInput
        label={words.s2_password}
        type="password"
        value={confirmPassword}
        onChange={(event) => setConfirmPassword(event.target.value)}
        error={confirmPassword.length !== 0 && password !== confirmPassword ? words.password_dose_not_matched : ''}
        isPassword
        className={confirmPassword.length !== 0 && password !== confirmPassword ? 'is-invalid' : ''}
      />

      <Button variant="primary" className="mt-4" type="submit">
        {words.change_password}
      </Button>
    </Form>
  );
};
