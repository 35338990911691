import { createSlice } from '@reduxjs/toolkit';
import {InitialStateTypes} from '../../models/redux'
import {USER_API} from '../../models/user'

import {
  getUser,
  setUser,
  removeUser} from '../../helper/authUtils'

import {
  loginAction, 
  LogoutAction, 
  ChangePasswordAction} from '../actions/userAction'

import {words} from '../../constants/translate'
import { toast } from 'react-toastify';

const initialState: InitialStateTypes = {
  isAuthenticated: false,
  user:null,
  isLoading: false,
  isRequesting: false,

  error: null,
};

export const reducer = createSlice({
  name: 'global',
  initialState,
  reducers: {
    authenticateUser: (state, action) => {
      state.isAuthenticated = true;
      state.user = action.payload
    },
    clearError: state => {
      state.error = null
    },
    initState: state => {
      state.isLoading=true
      let user: USER_API.User | null = null
      try {
        user = getUser()
        if (user && user?.token){
          state.isAuthenticated = true;
          state.user = user
        }
      } catch (error) {
        throw error
      }
      state.isLoading=false
    }},
  extraReducers: builder => {
    builder
          // *********** LOGIN START *********** \\
          .addCase(loginAction.pending, state => {
            state.isLoading = true
          })
          .addCase(loginAction.fulfilled, (state, action: any) => {
            if (action.payload.request.status === 200) {
              state.isAuthenticated = true
              setUser(action.payload.data)
            }            
            state.isLoading = false
            
          })
          .addCase(loginAction.rejected, (state, action: any) => {
            if (action.payload.request.status === 401) {
              state.isAuthenticated = false
              state.user = null
              removeUser()
              state.error = words.login_failed
            }else{
              state.error = words.something_wrong
            }
            state.isLoading = false
            // *********** LOGIN END *********** \\  
          })

          // *********** LOGOUT START *********** \\
          .addCase(LogoutAction.pending, state => {
            state.isLoading = true
          })
          .addCase(LogoutAction.fulfilled, (state, action: any) => {
            if (action.payload?.status || action.payload?.code === 200) {
              state.isAuthenticated = false
              removeUser()
              window.location.href = '/'
            }
            state.isLoading = false
          })
          .addCase(LogoutAction.rejected, (state, action: any) => {
            if (action.payload.request.status === 401) {
              state.isAuthenticated = false
              state.user = null
              removeUser()
            }else{
              state.error = words.something_wrong
            }
            state.isLoading = false
            // *********** LOGOUT END *********** \\  
          })       
          
          // *********** CHANGE PASSWORD START *********** \\
          .addCase(ChangePasswordAction.pending, state => {
            state.isRequesting = true
          })
          .addCase(ChangePasswordAction.fulfilled, (state, action: any) => {
            if (action.payload.request.status !== 200) {
              removeUser()
              state.isAuthenticated = false
              state.user = null
              toast.success("Password changed successfully")
              state.error=''
            }
            state.isRequesting = false
          })
          .addCase(ChangePasswordAction.rejected, (state, action: any) => {
            if (action.payload.request.status === 401) {
              state.isAuthenticated = false
              state.user = null
              removeUser()
              state.error = words.login_failed
              toast.error("Password change failed")
            }else if (action.payload.request.status === 400){
              state.error = words.password_invalid
              toast.error(words.password_invalid)
            }else{
              state.error = words.something_wrong
              toast.error(words.password_invalid)
            }        
            state.isRequesting = false
            // *********** CHANGE PASSWORD END *********** \\  
          })   
  },


});

export const { clearError, authenticateUser, initState } = reducer.actions;

export default reducer.reducer