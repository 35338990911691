import axios from 'axios';
import {BASE_URL} from '../constants/config'
import {getUser} from './authUtils'

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 90000, 
});

axiosInstance.interceptors.request.use((config) => {
  const user = getUser(); 
  if (user?.token) {
    config.headers['Authorization'] = `Token ${user.token}`;
  }
  return config;
});

export default axiosInstance;