
import React, { useState } from 'react';
import { Form as FormikForm, Field, ErrorMessage, useFormik, FieldProps } from 'formik';
import * as Yup from 'yup';
import { useMutation, useQuery } from 'react-query';
import { Form as BootstrapForm, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import apiInstance from '../helper/axiosInstance';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT, TRANSPORT_PRICE_ENDPOINT } from '../constants/endpoints';
import { GetAllLogisticsCompanies, GetAllVehiclesForCompany } from '../redux/services/logisticsServices';
import { Loading } from './loading';
import { AutocompleteInput } from './autoCompleteInput';
import { cites } from '../constants/cites';
import { COMPANY_API } from '../models/company';
import { toast } from 'react-toastify';

interface AddPriceFormProps {
    refresh: () => void;
}

const PriceFormSchema = Yup.object().shape({
    fromLocation: Yup.string().required(words.fields_required),
    toLocation: Yup.string().required(words.fields_required),
    price: Yup.number().typeError(words.price_invalid).required(words.fields_required),
    selectCompany: Yup.string().required(words.fields_required),
    selectedVehicle: Yup.number().required(words.fields_required)
});

export const AddPriceForm: React.FC<AddPriceFormProps> = ({ refresh }) => {
    const [error, setError] = useState('');


    const formikInitialValues = {
        fromLocation: 'ג\'לג\'וליה ',
        toLocation: 'אזור השרון ',
        price: '',
        selectCompany: '',
        selectedVehicle: ''
    };

    const formik = useFormik({
        initialValues: formikInitialValues,
        validationSchema: PriceFormSchema,
        onSubmit: (values) => {
            const newPrice = {
                from_location: values.fromLocation,
                to_location: values.toLocation,
                price: parseFloat(values.price),
                vehicle: values.selectedVehicle,
            };
            addPriceMutation.mutate(newPrice);
            formik.resetForm();
        },
    });

    const { data: companies, status: companies_status } = useQuery('companies', GetAllLogisticsCompanies);

    const { data: vehicles_companies, status: vehicles_companies_status, refetch: refetch_vehicles_companies } = useQuery('vehicles_companies', () => GetAllVehiclesForCompany(formik.values.selectCompany), {
        enabled: !!formikInitialValues.selectCompany
    });

    React.useEffect(() => {
        if (formik.values.selectCompany) {
            refetch_vehicles_companies();
        }
    }, [formik.values.selectCompany, refetch_vehicles_companies]);

    const addPriceMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.post(LOGISTICS_ENDPOINT + TRANSPORT_PRICE_ENDPOINT, data).then(() => {
                refresh();
                toast.success('הפעולה הושלמה בהצלחה');
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    if (companies_status === 'loading') {
        return <Loading />;
    }

    if (vehicles_companies_status === 'error' || companies_status === 'error') {
        return <Alert variant="danger">{words.something_wrong}</Alert>;
    }

    return (
        <BootstrapForm onSubmit={formik.handleSubmit} className="my-4">
            {error && <Alert variant="danger">{error}</Alert>}
            <div className="row">
                <div className="col-md-6">
                    <AutocompleteInput
                        suggestions={cites}
                        label={words.from_location}
                        type="text"
                        name="fromLocation"
                        onBlur={formik.handleBlur}
                        value={formik.values.fromLocation}
                        onSuggestionSelect={(selectedSuggestion) => formik.setFieldValue('fromLocation', selectedSuggestion)}
                        error={formik.touched.fromLocation && formik.errors.fromLocation}
                    />
                </div>
                <div className="col-md-6">
                    <AutocompleteInput
                        suggestions={cites}
                        label={words.to_location}
                        type="text"
                        name="toLocation"
                        onBlur={formik.handleBlur}
                        value={formik.values.toLocation}
                        onSuggestionSelect={(selectedSuggestion) => formik.setFieldValue('toLocation', selectedSuggestion)}
                        error={formik.touched.toLocation && formik.errors.toLocation}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <CustomInput
                        label={words.price}
                        type="text"
                        name="price"
                        onBlur={formik.handleBlur}
                        value={formik.values.price}
                        onChange={(e) => formik.setFieldValue('price', e.target.value)}
                        error={formik.touched.price && formik.errors.price}
                    />
                </div>
            </div>

            <div className="row">
                <div className="col-md-6">
                    <BootstrapForm.Group controlId="selectCompany">
                        <BootstrapForm.Label>{words.selectCompany}</BootstrapForm.Label>
                        <BootstrapForm.Control
                            as="select"
                            name="selectCompany"
                            value={formik.values.selectCompany}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.selectCompany && !!formik.errors.selectCompany}
                        >
                            <option value="" disabled>
                                {words.selectCompany}
                            </option>
                            {companies?.data.map((company: COMPANY_API.Company) => {
                                if (company.is_active) {
                                    return (
                                        <option key={company.id} value={company.id}>
                                            {`${company.name}`}
                                        </option>
                                    );
                                }
                                return null;
                            })}
                        </BootstrapForm.Control>
                        {formik.touched.selectCompany && formik.errors.selectCompany ? (
                            <BootstrapForm.Control.Feedback type="invalid">
                                {formik.errors.selectCompany}
                            </BootstrapForm.Control.Feedback>
                        ) : null}
                    </BootstrapForm.Group>
                </div>
                <div className="col-md-6">
                    <BootstrapForm.Group controlId="selectedVehicle">
                        <BootstrapForm.Label>{words.select_vehicle}</BootstrapForm.Label>
                        <BootstrapForm.Control
                            as="select"
                            name="selectedVehicle"
                            value={formik.values.selectedVehicle}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            isInvalid={formik.touched.selectedVehicle && !!formik.errors.selectedVehicle}
                        >
                            <option value="" disabled>
                                {words.select_vehicle}
                            </option>
                            {vehicles_companies_status === 'loading' ? (
                                <option value="" disabled>Loading...</option>
                            ) : (
                                vehicles_companies?.data?.map((vehicle: COMPANY_API.Vehicle) => (
                                    <option key={vehicle.id} value={vehicle.id}>
                                        {`${vehicle.name}`}
                                    </option>
                                ))
                            )}
                        </BootstrapForm.Control>
                        {formik.touched.selectedVehicle && formik.errors.selectedVehicle ? (
                            <BootstrapForm.Control.Feedback type="invalid">
                                {formik.errors.selectedVehicle}
                            </BootstrapForm.Control.Feedback>
                        ) : null}
                    </BootstrapForm.Group>
                </div>
            </div>

            <Button variant="primary" className="mt-4" type="submit">
                {words.add_new_price}
            </Button>
        </BootstrapForm>
    );

};
