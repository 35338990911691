import React from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Button, Alert } from 'react-bootstrap';
import {GetAllOrdersList} from '../redux/services/orderService'
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import OrderReport from '../components/orderReport';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';
import RequestReport from '../components/requestReport';
import { GetAllRequest } from '../redux/services/reuqestServer';


export default function Orders() {
  const [showOrderReport, setShowOrderReport] = React.useState(true);
  const [showRequestsReport, setShowRequestsReport] = React.useState(false);


  const { data:orders, status} = useQuery('ordersReport', GetAllOrdersList);
  const { data:requests, status:status_request, refetch } = useQuery('requests', GetAllRequest);
  const navigate = useNavigate();
  

  if (status === 'error' || status_request === 'error'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }

  if (status === 'loading' || status_request === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };


  return (
    <>
      <div className='d-flex justify-content-start mt-2'>
        <Button onClick={handleGoBack}  className="back-button responsive-button">
          {words.back} <FiArrowRight className="back-icon" />
        </Button>
      </div>
      

      <div className="userTableContainer my-4">
        <div className="d-flex justify-content-between title-container">
          <h3 className="title mx-2">{words.orders}</h3>
          <div className="icon-container" style={{zIndex:2}} onClick={() => setShowOrderReport(!showOrderReport)}>
            {showOrderReport ? (
              <FaCaretUp size={20} className="icon-up mx-4" />
            ) : (
              <FaCaretDown size={20} className="icon-down mx-4" />
            )}
          </div>
        </div>
        <div className={`ordersTableWrap ${showOrderReport ? 'showTable' : 'hideTable'}`}>
          {orders.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<OrderReport orders={orders.data} />}
        </div>
      </div>

      <div className="userTableContainer my-4">
        <div className="d-flex justify-content-between title-container">
          <h3 className="title mx-2">{words.request}</h3>
          <div className="icon-container" style={{zIndex:2}} onClick={() => setShowRequestsReport(!showRequestsReport)}>
            {showRequestsReport ? (
              <FaCaretUp size={20} className="icon-up mx-4" />
            ) : (
              <FaCaretDown size={20} className="icon-down mx-4" />
            )}
          </div>
        </div>
        <div className={`TableWrap ${showRequestsReport ? 'showTable' : 'hideTable'}`}>
          {requests.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<RequestReport requests={requests.data} />}
        </div>
      </div>

    </>
  );
}