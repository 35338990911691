import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { addDays, isAfter, isBefore, format } from 'date-fns';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { ORDER_ENDPOINT, STORE_ENDPOINT } from '../constants/endpoints';
import { COMPANY_API } from '../models/company';
import { ORDER_API } from '../models/order';
import CustomModal from './customModal';
import SelectorPrice from './selectorPrice';
import { cites } from '../constants/cites';
import { AutocompleteInput } from './autoCompleteInput';
import { TextArea } from './textArea';
import { REQUEST_API } from '../models/request';

interface EditOrderFormProps {
    onHide: () => void;
    object: ORDER_API.Data;
}

export const EditOrderForm: React.FC<EditOrderFormProps> = ({ onHide, object }) => {
    const [customerName, setCustomerName] = useState(object.order.customer_name);
    const [customerPhone, setCustomerPhone] = useState(object.order.customer_phone);
    const [deliveryPlace, setDeliveryPlace] = useState(object.order.delivery_place);
    const [addressDetails, setAddressDetails] = useState(object.order.address_details);
    const [deliveryStartTime, setDeliveryStartTime] = useState(object.order.delivery_start_time);
    const [deliveryEndTime, setDeliveryEndTime] = useState(object.order.delivery_end_time);

    const [deliveryDate, setDeliveryDate] = useState<Date | null>(new Date(object.order.delivery_date));
    const [deliveryPrice, setDeliveryPrice] = useState(object.order.delivery_price);
    const [note, setNote] = useState(object.order.note);

    const [deliveryHeight, setDeliveryHeight] = useState(object.order.delivery_height);
    const [itemsList, setItemList] = useState(object.items);

    const [done, setDone] = useState(object.order.done);
    const [vehicleObject, setVehicleObject] = useState<COMPANY_API.FullVehicle | null>(object.order.vehicle_id);
    const [showVehicleList, setShowVehicleList] = useState(false);

    const [customerNameError, setCustomerNameError] = useState('');
    const [customerPhoneError, setCustomerPhoneError] = useState('');
    const [deliveryPlaceError, setDeliveryPlaceError] = useState('');
    const [deliveryTimeStartError, setDeliveryTimeStartError] = useState('');
    const [deliveryDateError, setDeliveryDateError] = useState('');
    const [deliveryHeightError, setDeliveryHeightError] = useState('');

    const [error, setError] = useState('');

    const editOrderMutation = useMutation<void, unknown, any>(
        (data) => {
            return apiInstance.patch(STORE_ENDPOINT + ORDER_ENDPOINT + `${object.order.id}/`, data).then(() => {
                onHide();
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );


    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (!customerName) {
            setCustomerNameError(words.customer_name_required);
            return;
        }
        setCustomerNameError('');

        if (!deliveryPlace) {
            setDeliveryPlaceError(words.delivery_place_required);
            return;
        }
        setDeliveryPlaceError('');

        if (!deliveryStartTime) {
            setDeliveryTimeStartError(words.delivery_time_required);
            return;
        }
        setDeliveryTimeStartError('');

        if (!deliveryDate) {
            setDeliveryDateError(words.delivery_date_required);
            return;
        }
        setDeliveryDateError('');

        if (!deliveryHeight) {
            setDeliveryHeightError(words.delivery_height_required);
            return;
        }
        setDeliveryHeightError('');

        setError('');
        const data = {
            customer_name: customerName,
            delivery_place: deliveryPlace,
            address_details: addressDetails,
            delivery_start_time: String(deliveryStartTime),
            delivery_end_time: deliveryEndTime ? String(deliveryEndTime) : String(deliveryStartTime),
            delivery_date: format(deliveryDate, 'yyyy-MM-dd'),
            delivery_height: deliveryHeight,
            delivery_price: deliveryPrice,
            done: done,
            note: note,
            vehicle_id: vehicleObject?.id,

        };
        editOrderMutation.mutate(data);

        setCustomerName('');
        setCustomerPhone('')
        setDeliveryPlace('');
        setAddressDetails('')
        setDeliveryStartTime('');
        setDeliveryEndTime('');
        setDeliveryDate(null);
        setDeliveryHeight('');
        setDone(false);
        setNote('')
        setVehicleObject(null);
    };

    const handleShowPriceList = () => {
        setShowVehicleList(true)
    }
    const handleHidePriceList = () => {
        setShowVehicleList(false)
    }
    const handlePickVehivle = (vehicle: COMPANY_API.FullVehicle, price: any) => {
        setVehicleObject(vehicle)
        setDeliveryPrice(price)
        setShowVehicleList(false)
    }
    const handleDateChange = (date: Date) => {
        const today = new Date();
        const sevenDaysAgo = addDays(today, -7);
        const thirtyDaysFromToday = addDays(today, 30);

        if (isBefore(date, sevenDaysAgo) || isAfter(date, thirtyDaysFromToday)) {
            setDeliveryDateError(words.invalid_date_range);
            setDeliveryDate(null);
        } else {
            setDeliveryDateError('');
            setDeliveryDate(date);
        }
    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                {error && <Alert variant="danger" className="mb-3">{error}</Alert>}

                <div className="row mb-3">
                    <div className="col-md-6">
                        <CustomInput
                            label={words.customer_name}
                            type="text"
                            value={customerName}
                            onChange={(event) => setCustomerName(event.target.value)}
                            error={customerNameError}
                        />
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.customer_mobile_number}
                            type="text"
                            value={customerPhone}
                            onChange={(event) => setCustomerPhone(event.target.value)}
                            error={customerPhoneError}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6">
                        <AutocompleteInput
                            suggestions={cites}
                            label={words.delivery_place}
                            showListOnClick
                            matchedRequired
                            type="text"
                            value={deliveryPlace}
                            onSuggestionSelect={(value) => {
                                setDeliveryPlace(value)
                            }}
                            error={deliveryPlaceError}
                        />
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.address}
                            type="text"
                            value={addressDetails}
                            onChange={(e) => setAddressDetails(e.target.value)}

                        />
                    </div>

                </div>

                <div className='row mb-1'>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.delivery_height}
                            type="text"
                            value={deliveryHeight}
                            onChange={(event) => setDeliveryHeight(event.target.value)}
                            error={deliveryHeightError}
                        />
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-6 d-flex flex-column ">
                        <label htmlFor="deliveryDateInput" className="mb-1">
                            {words.delivery_date}
                        </label>
                        <DatePicker
                            id="deliveryDateInput"
                            selected={deliveryDate}
                            onChange={handleDateChange}
                            className="form-control"
                            dateFormat="yyyy-MM-dd"
                        />
                        {deliveryDateError && <div className="text-danger mt-1">{deliveryDateError}</div>}
                    </div>
                </div>

                <div className='row mb-3'>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeStartInput" className="mb-1">
                                {words.From_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeStartInput"
                                value={deliveryStartTime}
                                onChange={(event) => setDeliveryStartTime(event.target.value)}
                                className="form-control"
                            />
                            {deliveryTimeStartError && <div className="text-danger mt-1">{deliveryTimeStartError}</div>}
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="deliveryTimeEndInput" className="mb-1">
                                {words.To_time}
                            </label>
                            <input
                                type="time"
                                id="deliveryTimeEndInput"
                                value={deliveryEndTime ? deliveryEndTime : deliveryStartTime}
                                onChange={(event) => setDeliveryEndTime(event.target.value)}
                                className="form-control"
                            />
                        </div>
                    </div>
                </div>

                <div className="row mb-3">
                    <div className="col-md-3">
                        <CustomInput
                            label={words.vehicle_number}
                            type="text"
                            value={vehicleObject ? vehicleObject.name : words.not_selected}
                            disable={true}
                        />
                    </div>
                    <div className="col-md-3 align-items-center align-self-center mt-3">
                        <Button variant="warning" onClick={() => handleShowPriceList()}>
                            {words.selectVehicle}
                        </Button>
                    </div>
                    <div className="col-md-6">
                        <CustomInput
                            label={words.price}
                            type="text"
                            value={deliveryPrice}
                            disable={true}
                        />
                    </div>
                </div>

                <div className='row border m-2'>
                    <div className="p-4">
                        <ul className="list-group mb-3 items-list ">
                            {
                                itemsList.length === 0 ?
                                    <Alert variant='warning'>{words.add_items}</Alert>
                                    :
                                    itemsList.map((option: ORDER_API.itemData, index: React.Key | null | undefined) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                            {option.item_name} * {option.quantity}
                                        </li>
                                    ))
                            }
                        </ul>
                    </div>
                </div>

                <div className='row mb-3'>
                    <TextArea
                        label={words.note}
                        value={note?note:''}
                        onChange={(e) => setNote(e.target.value)} />
                </div>

                <div className="row my-2">
                    <div className="col-md-6">
                        <Form.Check
                            inline
                            type="checkbox"
                            label={words.done}
                            checked={done}
                            onChange={() => setDone(!done)}
                        />
                    </div>
                </div>
                <div className="d-flex justify-content-center mt-4">
                    <Button
                        variant="primary"
                        className="responsive-button"
                        size="lg"
                        type="submit"
                        disabled={customerName.length < 1 || deliveryPlace.length < 1}
                    >
                        {words.save_changes}
                    </Button>
                </div>
            </Form>


            <CustomModal show={showVehicleList} onHide={handleHidePriceList} size='xl' title={words.price_table_list}>
                <SelectorPrice pick={handlePickVehivle} />
            </CustomModal>
        </>

    );
};
