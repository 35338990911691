import { BrowserRouter, Routes, Route } from "react-router-dom";

import {
  Login,
  NotFoundPage
} from '../screens'

export function PublicStack() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" >
          <Route index element={<Login />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </BrowserRouter>

  );
}