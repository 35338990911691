import React, { useState } from 'react';
import { Table, Form, InputGroup, Pagination, Button } from 'react-bootstrap';
import '../assets/CSS/priceTable.css'
import { words } from '../constants/translate';
import { FaEdit} from 'react-icons/fa';
import { PRICE_API } from '../models/price';
import { useQuery } from 'react-query';
import { GetTransportPriceList } from '../redux/services/transportServices';
import { Loading } from './loading';
import { COMPANY_API } from '../models/company';

interface SelectorPriceProps {
   pick:(vehicle:COMPANY_API.FullVehicle, price:any)=>void
}


const SelectorPrice: React.FC<SelectorPriceProps> = ({pick}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [sortColumn, setSortColumn] = useState('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { data, status } = useQuery('priceData', GetTransportPriceList);

  if (status === 'loading') {
    return <Loading />;
  }


  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1);
  };

  const filteredPrices = data.data.filter((price:PRICE_API.Price) => {
    const searchValue = searchTerm.toLowerCase();
    return (
      price.vehicle.height.toLowerCase().includes(searchValue) ||
      price.vehicle.weight.toLowerCase().includes(searchValue)
    );
  });

  const handleSort = (columnName: keyof PRICE_API.Price) => {
    if (sortColumn === columnName) {
      setSortDirection(prevSortDirection =>
        prevSortDirection === 'asc' ? 'desc' : 'asc'
      );
    } else {
      setSortColumn(columnName);
      setSortDirection('asc');
    }
  };

  const sortedPrices = filteredPrices.sort((a:PRICE_API.Price, b:PRICE_API.Price) => {
    const isAsc = sortDirection === 'asc';
    let aValue: number | string;
    let bValue: number | string;

    if (sortColumn === 'from_location' || sortColumn === 'to_location') {
      aValue = a[sortColumn];
      bValue = b[sortColumn];
    } else if (sortColumn === 'vehicle.name') {
      aValue = a.vehicle.name;
      bValue = b.vehicle.name;
    } else if (sortColumn === 'price') {
      aValue = a.price;
      bValue = b.price;
    } else {
      aValue = '';
      bValue = '';
    }

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      aValue = aValue.toLowerCase();
      bValue = bValue.toLowerCase();
    }

    if (aValue < bValue) {
      return isAsc ? -1 : 1;
    }
    if (aValue > bValue) {
      return isAsc ? 1 : -1;
    }
    return 0;
  });

  const ITEMS_PER_PAGE = 10;
  const totalPages = Math.ceil(sortedPrices.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const paginatedPrices = sortedPrices.slice(startIndex, endIndex);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
    <div className="table-responsive">
      <Form className="my-2">
        <Form.Group controlId="search">
          <InputGroup>
            <Form.Control
              className='customSearch p-3'
              type="text"
              placeholder={words.search}
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </InputGroup>
        </Form.Group>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th onClick={() => handleSort('from_location')}>{words.from_location}</th>
            <th onClick={() => handleSort('to_location')}>{words.to_location}</th>
            <th onClick={() => handleSort('price')}>{words.price}</th>
            <th onClick={() => handleSort('vehicle')}>{words.vehicle_name}</th>
            <th>{words.height}</th>
            <th>{words.weight}</th>
            <th>{words.driver_mobile_number}</th>
            <th>{words.company_details}</th>
            <th>{words.select_vehicle}</th>
          </tr>
        </thead>
        <tbody>
          {paginatedPrices.map((price:PRICE_API.Price) => (
            <tr key={price.id}>
              <td>{price.from_location}</td>
              <td>{price.to_location}</td>
              <td>{price.price}</td>
              <td>{price.vehicle.name}</td>
              <td>{price.vehicle.height}</td>
              <td>{price.vehicle.weight}</td>
              <td>{price.vehicle.driver_mobile_number}</td>
              <td>{price.vehicle.company}</td>
              <td>
                <Button variant="warning" size='sm' onClick={()=>pick(price.vehicle, price.price)}>
                  <FaEdit className="button-icon" /> <span>{words.select_vehicle}</span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>


      <div className="d-flex justify-content-center">
        <Pagination>
          {Array.from({ length: totalPages }).map((_, index) => (
            <Pagination.Item
              key={index}
              active={index + 1 === currentPage}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </Pagination.Item>
          ))}
        </Pagination>
      </div>
      
    </div>
    </>
  );
};

export default SelectorPrice;

