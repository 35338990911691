import React from 'react';
import './App.css'
import { useAppDispatch, useAppSelector } from './redux/store';
import { initState } from './redux/reducers';
import {getUser} from './helper/authUtils'
import { PublicStack, PrivateStack } from './stacks';
import {Loading} from './components/loading'
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: true,
      refetchOnReconnect: false,
      retry: false,
    },
  },
})
const App = () => {
  const dispatch = useAppDispatch();
  const {isLoading, isAuthenticated,  } = useAppSelector(state => state.global);

  const get_data = async () => {
      dispatch(initState())
  }

  React.useEffect(() => {
    get_data()
  }, [])


  return (
    <div className='App'>
      
      {isLoading ? (
        <Loading/>
      ) : getUser()?.token || isAuthenticated ? (

        <QueryClientProvider client={queryClient}>
          <PrivateStack />
        </QueryClientProvider>

      ) : (
        <PublicStack />
      )}
    </div>
  );
}

export default App;