import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from './input';
import { words } from '../constants/translate';
import { COMPANY_API } from '../models/company';
import { LOGISTICS_ENDPOINT } from '../constants/endpoints';

interface EditVehicleFormProps {
  onHide: () => void;
  vehicle: COMPANY_API.Vehicle;
}

export const EditVehicleForm: React.FC<EditVehicleFormProps> = ({ onHide, vehicle }) => {
  const [error, setError] = useState('');
  const [name, setName] = useState(vehicle.name);
  const [height, setHeight] = useState(vehicle.height);
  const [weight, setWeight] = useState(vehicle.weight);
  const [driverMobileNumber, setDriverMobileNumber] = useState(vehicle.driver_mobile_number);

  const editVehicleMutation = useMutation<void, unknown, COMPANY_API.Vehicle>(
    (data) => {
      return apiInstance.patch(LOGISTICS_ENDPOINT + `vehicle/${vehicle.id}/`, data).then(() => {
        onHide();
      });
    },
    {
      onError: () => {
        setError(words.something_wrong);
      },
    }
  );

  const handleHeightChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^\d]/g, '');
    if (value.length <= 3) {
      setHeight(value);
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!name) {
      setError(words.name_required);
      return;
    }

    const updatedVehicle: COMPANY_API.Vehicle = {
      ...vehicle,
      name,
      height,
      weight:weight,
      driver_mobile_number: driverMobileNumber,
      company: vehicle.company.id
    };

    editVehicleMutation.mutate(updatedVehicle);

    setName('');
    setHeight('');
    setDriverMobileNumber('');
  };

  return (
    <Form onSubmit={handleSubmit} className="my-4">
      {error && <Alert variant="danger">{error}</Alert>}

      <div className="row">
        <div className="col-md-6">
          <CustomInput
            label={words.name}
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
            className="mb-3"
          />
        </div>

        <div className="col-md-6">
          <CustomInput
            label={words.height}
            type="text"
            value={height}
            onChange={handleHeightChange}
            className="mb-3"
          />
        </div>

      </div>

      <div className="row">

      <div className="col-md-6">
          <CustomInput
            label={words.weight}
            type="text"
            value={weight}
            onChange={(event) => setWeight(event.target.value)}
            className="mb-3"
          />
        </div>

        <div className="col-md-6">
          <CustomInput
            label={words.driver_mobile_number}
            type="number"
            value={driverMobileNumber}
            onChange={(event) => setDriverMobileNumber(event.target.value)}
            className="mb-3"
          />
        </div>
      </div>

      <div className="d-flex justify-content-center mt-4">
        <Button variant="primary" className="responsive-button" type="submit">
          {words.save_changes}
        </Button>
      </div>
    </Form>

  );
};
