import React, { useState } from 'react';
import { Table, Button } from 'react-bootstrap';
import { FaCaretUp, FaCaretDown, FaEdit, FaTrash } from 'react-icons/fa';
import '../assets/CSS/table.css';
import { words } from '../constants/translate'
import CustomModal from './customModal'
import { EditPasswordForm } from '../components/editPasswordForm'
import { DeleteUserForm } from '../components/deleteUserForm'
import { getUser } from '../helper/authUtils';

interface User {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  role: string;
  last_login: string;
  is_active: boolean;
  [key: string]: any;
}

interface UserTableProps {
  users: User[];
  refresh: () => any
}

export const UserTable: React.FC<UserTableProps> = ({ users, refresh }) => {
  const [showEditUserModal, setShowEditUserModal] = React.useState(false);
  const [showDeleteUserModal, setShowDeleteUserModal] = React.useState(false);
  const [targetID, setTargetID] = React.useState('');
  const [usernameTarget, setUsernameTarget] = React.useState('');

  const user = getUser()
  const [sortedField, setSortedField] = useState<string | null>(null);
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const handleShowEditUserModal = (id: string, username: string) => {
    setShowEditUserModal(true);
    setTargetID(id)
    setUsernameTarget(username)
  };

  const handleHideEditUserModal = () => {
    setShowEditUserModal(false);
    setTargetID('')
    setUsernameTarget('')
    refresh()
  };

  const handleShowDeleteUserModal = (id: string, username: string) => {
    setShowDeleteUserModal(true);
    setTargetID(id)
    setUsernameTarget(username)
  };

  const handleHideDeleteUserModal = () => {
    setShowDeleteUserModal(false);
    setTargetID('')
    setUsernameTarget('')
    refresh()
  };


  const handleSort = (field: string) => {
    if (sortedField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortedField(field);
      setSortDirection('asc');
    }
  };

  const sortedUsers = users.slice().sort((a, b) => {
    if (sortedField) {
      const aValue = a[sortedField];
      const bValue = b[sortedField];

      if (typeof aValue === 'boolean' && typeof bValue === 'boolean') {
        if (aValue === bValue) {
          return 0;
        }
        return aValue === true ? -1 : 1;
      }

      return aValue.localeCompare(bValue) * (sortDirection === 'asc' ? 1 : -1);
    }
    return 0;
  });

  const getSortArrows = (field: string) => {
    const isSortedField = sortedField === field;
    const isAsc = sortDirection === 'asc';

    const upArrow = isSortedField && isAsc ? <FaCaretUp /> : <FaCaretUp style={{ opacity: 0.3 }} />;
    const downArrow = isSortedField && !isAsc ? <FaCaretDown /> : <FaCaretDown style={{ opacity: 0.3 }} />;

    return (
      <div className="flex-center">
        <div className="mr-2">
          {upArrow}
        </div>
        <div>
          {downArrow}
        </div>
      </div>
    );
  };

  return (
    <>
      <Table striped bordered hover responsive className="custom-table">
        <thead>
          <tr>
            <th onClick={() => handleSort('username')} className="sort-header">
              <div className="flex-center">
                {words.username} {getSortArrows('username')}
              </div>
            </th>
            <th onClick={() => handleSort('first_name')} className="sort-header">
              <div className="flex-center">
                {words.name} {getSortArrows('first_name')}
              </div>
            </th>
            <th onClick={() => handleSort('phone_number')} className="sort-header">
              <div className="flex-center">
                {words.mobile_number} {getSortArrows('phone_number')}
              </div>
            </th>
            <th >{words.role}</th>
            {user?.is_superuser ?
              <>
                <th>{words.actions}</th>
              </>
              :
              null

            }

          </tr>
        </thead>
        <tbody>
          {sortedUsers.map((userRecord:any) => (
            <tr key={userRecord.id}>
              <td>{userRecord.username}</td>
              <td>{`${userRecord.first_name} ${userRecord.last_name}`}</td>
              <td style={{direction:'ltr'}}>{userRecord.phone_number}</td>
              <td>{userRecord.role === 'worker' ? words.worker : words.manager}</td>
              {
                user?.is_superuser ?
                  <>
                    <td>
                      <Button variant="primary" className='responsive-button m-1' onClick={() => handleShowEditUserModal(`${userRecord.id}`, userRecord.username)}>
                        <FaEdit className="button-icon" /> <span>{words.edit_action}</span>
                      </Button>
                      <Button variant="danger" className='responsive-button m-1' onClick={() => handleShowDeleteUserModal(`${userRecord.id}`, userRecord.username)}>
                        <FaTrash className="button-icon" /> <span>{words.delete_action}</span>
                      </Button>
                    </td>
                  </>
                  :
                  null
              }

            </tr>
          ))}
        </tbody>
        
      </Table>
      <CustomModal show={showEditUserModal} onHide={handleHideEditUserModal} title={words.edit_action + ' ' + words.password + ' ' + usernameTarget}>
        <EditPasswordForm id={targetID} onHide={handleHideEditUserModal} />
      </CustomModal>

      <CustomModal show={showDeleteUserModal} onHide={handleHideDeleteUserModal} title={words.delete_action + ' ' + usernameTarget}>
        <DeleteUserForm onHide={handleHideDeleteUserModal} id={targetID} username={usernameTarget} />
      </CustomModal>
    </>
  );
};
