export const LOGIN_ENDPOINT = 'login/'
export const LOGOUT_ENDPOINT = 'logout/'
export const CHANGE_PASSWORD_ENDPOINT = 'change-password/'
export const USERS_ENDPOINT = 'users/'
export const CHANGE_USERS_PASSWORD_ENDPOINT = 'change-user-password/'

export const STORE_ENDPOINT = 'store/'
export const ITEM_ENDPOINT = 'item/';
export const ORDER_ENDPOINT = 'order/';

export const LOGISTICS_ENDPOINT = 'logistics/';
export const REQUEST_ENDPOINT = 'request/';
export const SUPPLIER_ENDPOINT = 'supplier/';


export const VEHICLE_ENDPOINT = 'vehicle/';
export const TRANSPORT_PRICE_ENDPOINT = 'transport-price/';

export const LOGISTICS_COMPANY_VEHICLE_ENDPOINT = '/logistics-company-vehicle/';