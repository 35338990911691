import React, { useState } from 'react';
import { useMutation } from 'react-query';
import apiInstance from '../helper/axiosInstance';
import { Form, Button, Alert } from 'react-bootstrap';
import { words } from '../constants/translate';
import { LOGISTICS_ENDPOINT, REQUEST_ENDPOINT } from '../constants/endpoints';
import { REQUEST_API } from '../models/request';
import { toast } from 'react-toastify';

interface DeleteRequestFormProps {
    onHide: () => void;
    request: REQUEST_API.Request;
}

export const DeleteRequestForm: React.FC<DeleteRequestFormProps> = ({ onHide, request }) => {
    const [error, setError] = useState('');
        
    const deleteRequestMutation = useMutation<void, unknown>(() => {
        return apiInstance.delete(`${LOGISTICS_ENDPOINT}${REQUEST_ENDPOINT}${request.id}/`).then(() => {
            onHide();
            toast.info('הפעולה הושלמה בהצלחה');
        });
    }, {
        onError: () => {
            setError(words.something_wrong);
        }
    });

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        deleteRequestMutation.mutate();
    };

    return (
        <Form onSubmit={handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}

            <Alert variant="secondary" className="mt-4">
                {words.delete_action} {words.request}: {request.id}
            </Alert>

            <Button
                variant="danger"
                style={{ marginTop: '20px', width: '100%' }}
                type="submit"
            >
                {words.delete_action}
            </Button>
        </Form>
    );
};
