import React from 'react';
import { useMutation } from 'react-query';
import { Button, Alert, Col, Form } from 'react-bootstrap';
import { useFormik, } from 'formik';
import * as Yup from 'yup';
import apiInstance from '../helper/axiosInstance';
import { words } from '../constants/translate';
import { STORE_ENDPOINT, ITEM_ENDPOINT } from '../constants/endpoints';
import { CustomInput } from './input';
import { toast } from 'react-toastify';

interface AddNewItemFormProps {
    refresh: () => void;
}

const ItemSchema = Yup.object().shape({
    itemName: Yup.string().required(words.fields_required)
});

export const AddNewItemForm: React.FC<AddNewItemFormProps> = ({ refresh }) => {
    const [error, setError] = React.useState<string | null>(null);

    const addNewItemMutation = useMutation<void, unknown, { name: string }>(
        (data) => {
            return apiInstance.post(`${STORE_ENDPOINT}${ITEM_ENDPOINT}`, data).then(() => {
                refresh();
                toast.success('הפעולה הושלמה בהצלחה');
            });
        },
        {
            onError: () => {
                setError(words.something_wrong);
            },
        }
    );

    const formik = useFormik({
        initialValues: {
            itemName: ''
        },
        validationSchema: ItemSchema,
        onSubmit: (values) => {
            let newItem = {
                name: values.itemName
            }
            addNewItemMutation.mutate(newItem);
            formik.resetForm()
        }
    });

    return (
        <Form onSubmit={formik.handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}

            <div className="row">
                <div className="col-md-6">
                    <CustomInput
                        type='text'
                        label={words.item_name}
                        name='itemName'
                        onChange={formik.handleChange}
                        value={formik.values.itemName}
                        onBlur={formik.handleBlur}
                        error={formik.touched.itemName && formik.errors.itemName}
                    />
                </div>
            </div>

            <Button variant="primary" className="mt-4" type="submit">
                {words.add_items}
            </Button>
        </Form>

    );
};
