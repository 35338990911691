import { useState } from 'react';
import { useQuery } from 'react-query';
import { Loading } from '../components/loading';
import { words } from '../constants/translate';
import { Container, Button, Alert } from 'react-bootstrap';
import { FaPlusCircle } from 'react-icons/fa';
import { FiArrowRight } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import CustomModal from '../components/customModal';
import { GetAllRequest } from '../redux/services/reuqestServer';
import RequestTable from '../components/requestTable';
import { AddNewRequestForm } from '../components/addNewRequestForm';
import { GetAllOrdersList } from '../redux/services/orderService';


export default function Requests() {

  const [showNewRequest, setShowNewRequest] = useState(false);
  const { data:requests, status, refetch } = useQuery('requests', GetAllRequest);
  const { data:orders, status:order_status} = useQuery('orders', GetAllOrdersList);
  const navigate = useNavigate();
  
  const handleCloseNewRequestModal = () => {
    refetch();
    setShowNewRequest(false);
  };

  const handleShowNewRequestModal = () => {
    setShowNewRequest(true);
  };

  if (status === 'error' || order_status === 'error'){
    return <Alert variant="danger">{words.something_wrong}</Alert>
  }

  if (status === 'loading' || order_status === 'loading'){
    return <Loading/>
  }

  const handleGoBack = () => {
    navigate('/');
  };


  return (
    <>
      <div className='d-flex justify-content-start mt-2'>
        <Button onClick={handleGoBack}  className="back-button responsive-button">
          {words.back} <FiArrowRight className="back-icon" />
        </Button>
      </div>
 
      <Container fluid className="my-4 actionBar">
        <Button variant="success" size="lg" onClick={handleShowNewRequestModal}>
          <FaPlusCircle /> {words.add_new_request}
        </Button>
      </Container>

      <div className="supplierTableContainer my-4">
        <div className="title-container">
          <h3 className="title">{words.request_page}</h3>
        </div>
        <div className={`TableWrap`}>
          {requests.data.length === 0 ? <Alert variant='warning'>{words.no_data_available}</Alert>:<RequestTable data={requests.data} refresh={refetch} />}
        </div>
      </div>

      <CustomModal show={showNewRequest} onHide={handleCloseNewRequestModal} title={words.add_new_request}>
        <AddNewRequestForm refresh={refetch} data={orders.data} />
      </CustomModal>
    </>
  );
}