import * as Yup from 'yup';
import { useFormik } from 'formik';
import { Card, Form, Button, Alert } from 'react-bootstrap';
import { CustomInput } from '../components/input';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { clearError } from '../redux/reducers/index';
import { loginAction } from '../redux/actions/userAction';
import { words } from '../constants/translate';

export interface ILoginProps {}

const LoginSchema = Yup.object().shape({
  username: Yup.string().min(4, 'קצר מדי!').max(50, 'ארוך מדי!').required('נדרש'),
  password: Yup.string().min(4, 'קצר מדי!').max(50, 'ארוך מדי!').required('נדרש'),
});

export default function Login(props: ILoginProps) {
  const { isLoading, error } = useAppSelector(state => state.global);
  const dispatch = useAppDispatch();

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: values => {
      dispatch(clearError())
      dispatch(loginAction(values));
    },
  });

  return (
    <div className="d-flex align-items-center justify-content-center vh-100 bg-light">
      <Card
        style={{
          width: '100%',
          maxWidth: '400px',
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.3)',
          borderRadius: '15px',
        }}
      >
        <Card.Body className="d-flex flex-column align-items-center">
          <Card.Title className="text-center mb-4" style={{ fontSize: '24px' }}>
            {words.login}
          </Card.Title>
          <Form style={{ width: '100%' }} onSubmit={formik.handleSubmit}>
            {error && <Alert variant="danger">{error}</Alert>}
            <CustomInput
              label={words.username}
              placeholder={words.username_placeholder}
              value={formik.values.username}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="username"
              error={formik.touched.username && formik.errors.username ? formik.errors.username : undefined}
            />
            <CustomInput
              label={words.password}
              placeholder={words.password_placeholder}
              isPassword={true}
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="password"
              error={formik.touched.password && formik.errors.password ? formik.errors.password : undefined}
            />
            <Button
              variant="primary"
              type="submit"
              className="w-100 mt-3"
              disabled={isLoading}
              style={{ fontSize: '18px' }}
            >
              {words.login}
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </div>
  );
}
